import { Avatar, Backdrop, Box, Button, Checkbox, CircularProgress, Collapse, Fade, FormControlLabel, FormGroup, Grid, IconButton, Input, List, ListItem, ListItemAvatar, ListItemButton, ListItemIcon, ListItemText, MenuItem, Modal, Paper, Stack, Switch, TextField, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import dayjs from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker, TimePicker, LocalizationProvider, MobileTimePicker } from "@mui/x-date-pickers";
import AddTaskIcon from '@mui/icons-material/AddTask';
import { Calendar } from "react-multi-date-picker"
import TourIcon from '@mui/icons-material/Tour';
import { useDispatch, useSelector } from "react-redux";
import { assign_staff_worker, edit_information, shift_check_list, visit_days } from "../../../middleware/reducers/service_request";
import DeleteIcon from '@mui/icons-material/Delete';
import { Add, ExpandLess, ExpandMore } from "@mui/icons-material";
import _ from "lodash";
import { OrganizationList, PriceLists } from "../../../services/admin_service";
import moment from "moment";
import { GetStaffDetailsWithId, StaffDetailsList } from "../../../services/staff_service";
import { GetStaffList, WorkingDaysByFilters } from "../../../services/schedule_service";
import { ChargeBandRates, ServicePlansFilter } from "../../../services/finance_service";

const monthsList = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Des"]

const randomIds = () => {
    return Math.floor((1 + Math.random()) * 0x10000)
        .toString(16)
        .substring(1);
}

export const Information = ({ screenWidth }) => {
    const [orgnizations, loading_org] = OrganizationList();
    const _client_info = useSelector((state) => state._service_request.information);
    const _client_edit_info = useSelector((state) => state._service_request.editInformation);
    const [priceLists, loading] = PriceLists();
    const [getChargeBandRates, loading_band, error_band] = ChargeBandRates()

    const dispatch = useDispatch();

    const [information, setInformation] = useState([
        {
            title: "First Name",
            value: "",
            placeholder: "",
            width: screenWidth,
            type: "text",
            required: true,
            disabled: true,
        },
        {
            title: "Last Name",
            value: "",
            placeholder: "",
            width: screenWidth,
            type: "text",
            required: true,
            disabled: true,
        },
        {
            title: "Date of Birth",
            value: moment().format("DD-MM-YYYY"),
            type: "date",
            width: screenWidth,
            required: true,
            disabled: true,
        },
        {
            title: "Gender",
            value: "Male",
            width: screenWidth,
            type: "drop_down",
            required: true,
            options: [
                {
                    title: "Male",
                    selected: true
                },
                {
                    title: "Female",
                    selected: false
                },
                {
                    title: "Others",
                    selected: false
                }
            ],
            disabled: true,
        },
        {
            title: "Funding Source",
            value: "Self Funded",
            width: screenWidth,
            type: "drop_down",
            required: true,
            options: [
                {
                    title: "Self Funded",
                    selected: true
                },
                {
                    title: "NDIS Funded",
                    selected: false
                },
                {
                    title: "Organization Funded",
                    selected: false
                },

            ],
            disabled: false,
        },
        {
            title: "Service Type",
            value: {
                title: "Please select service type",
                id: "DF1"
            },
            width: screenWidth,
            type: "drop_down",
            required: true,
            disabled: false,
            options: [
                {
                    title: "Please select service type",
                    id: "DF1"
                }
            ]
        },
        // {
        //     title: "Remove Plan Managers",
        //     value: "No",
        //     width: screenWidth,
        //     type: "drop_down",
        //     required: true,
        //     disabled: false,
        //     options: [
        //         {
        //             title: "Yes",
        //             selected: true
        //         },
        //         {
        //             title: "No",
        //             selected: false
        //         },
        //     ],

        // },
        {
            title: "Billable",
            value: "Yes",
            width: screenWidth,
            type: "drop_down",
            required: true,
            disabled: false,
            options: [
                {
                    title: "Yes",
                    selected: true
                },
                {
                    title: "No",
                    selected: false
                },
            ]
        },
    ]);

    const [selectOrganization, setSelectOrganization] = useState({
        title: "Organization List",
        value: {
            title: "Please Select Organization",
            id: "D1"
        },
        options: [
            {
                title: "Please Select Organization",
                id: "D1"
            }
        ]
    });

    useEffect(() => {
        // if (priceLists) {
        //     console.log("priceLists", priceLists);
        //     const copy = [...information]
        //     const reStruct = [];
        //     for (const key in priceLists) {
        //         reStruct.push({
        //             title: priceLists[key]?.attributes?.service_name,
        //             id: priceLists[key]?.id
        //         })
        //     }
        //     copy[5].options = reStruct;
        //     setInformation(copy)
        // }
        if (orgnizations?.length) {
            const copy = { ...selectOrganization }
            for (const key in orgnizations) {
                const data = orgnizations[key]?.attributes;
                const find = copy.options.find((e) => e?.id === orgnizations[key]?.id)
                if (!find) {
                    const obj = {
                        title: data?.company_name,
                        id: orgnizations[key]?.id
                    }
                    copy.options.push(obj)
                }
            }
            // copy.value = {
            //     title: orgnizations[0]?.attributes?.company_name,
            //     id: orgnizations[0]?.id
            // }
            setSelectOrganization(copy)
        }
    }, [priceLists, orgnizations]);

    const getFilterChargeBands = async (id) => {
        try {
            const respons = await getChargeBandRates({
                variables: {
                    "filter": {
                        "charge_band_detail": {
                            "id": {
                                "eq": id
                            }
                        }
                    }
                }
            })
            if (respons?.data) {
                const data = respons.data.chargeBandRates.data;
                // console.log(data);
                const copy = [...information]
                const reStruct = [...copy[5].options];
                for (const key in data) {
                    reStruct.push({
                        title: data[key]?.attributes?.service_title,
                        id: data[key]?.id
                    })
                }
                copy[5].options = reStruct;
                setInformation(copy)
            }
        } catch (error) {
            console.log("error", error)
        }
    }

    useEffect(() => {
        if (_client_info) {
            const dbData = _client_info;
            // console.log("dbData", dbData)
            getFilterChargeBands(dbData.attributes.charge_band_detail_id.data.id)
            const copy = [...information];
            for (const key1 in copy) {
                for (const key in dbData.attributes) {
                    const property = key?.split("_").join(" ");
                    if (copy[key1].title.toLowerCase() === property) {
                        copy[key1].value = dbData.attributes[key]
                    }
                }
                for (const key in dbData.clientAnswerLovsReferences) {
                    const data = dbData.clientAnswerLovsReferences[key].attributes;
                    const property = data.input_name?.split("_").join(" ").toLowerCase();
                    if (copy[key1].title.toLowerCase() === property) {
                        copy[key1].value = data?.selected_option_value;
                    }
                }
            }
            setInformation(copy)
        }
    }, [_client_info]);

    useEffect(() => {
        if (information) {
            const copy = [...information];
            // const findService = priceLists?.find((e) => e?.attributes?.service_name === copy[5].value)
            let obj = {
                first_name: copy[0].value,
                last_name: copy[1].value,
                date_of_birth: copy[2].value,
                gender: copy[3].value,
                funding_source: copy[4].value,
                service_type: copy[5].value,
                // remove_plan_managers: copy[6].value,
                billable: copy[6].value,
                // organization: _client_info?.orgaizations,
                organization_list: selectOrganization.value?.id !== "D1" ? selectOrganization.value : "",
                pay_travel: "Yes",
            }

            dispatch(edit_information(obj));
        }
    }, [information, selectOrganization])

    useEffect(() => {
        if (_client_edit_info) {
            const copy = [...information];
            for (const key in _client_edit_info) {
                for (const data of copy) {
                    if (data?.title === "Service Type" && data?.title?.toLowerCase() === key?.split("_").join(" ")) {
                        data.value = _client_edit_info[key]?.attributes?.service_name
                    }
                    if (data?.title === "Funding Source" && data?.title?.toLowerCase() === key?.split("_").join(" ")) {
                        data.value = _client_edit_info[key]
                    }
                }
                if (key === "organization_list" && _client_edit_info[key]) {
                    selectOrganization.value = _client_edit_info[key]
                }
            }
            setInformation(copy)
        }
    }, [])

    // console.log("_client_info", _client_info)
    // console.log("_client_edit_info", _client_edit_info)
    return (
        <>
            <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
                {information.map((v, i) => {
                    return (
                        <div key={i}>
                            {v.type === "text" || v.type === "date" ? (
                                <TextField
                                    key={i}
                                    required={v.required}
                                    label={v.title}
                                    type={v.type}
                                    htmlFor={v.title}
                                    value={v.value}
                                    disabled={v.disabled}
                                    sx={{ m: 1, width: v.width }}
                                    onChange={(e) => {
                                        const copy = [...information]
                                        copy[i].value = e.target.value;
                                        setInformation(copy);
                                    }}
                                />
                            ) : null}
                            {v.type === "drop_down" ? (
                                <div key={v.title}>
                                    <TextField
                                        select={true}
                                        label={v.title}
                                        htmlFor={v.title}
                                        required={v.required}
                                        sx={{ m: 1, width: v.width }}
                                        disabled={v.disabled}
                                        value={v.title.includes("Service Type") ? v.value?.title : v.value}
                                        onChange={(e) => {
                                            const copy = [...information];
                                            if (v.title.includes("Service Type")) {
                                                const find = copy[i]?.options?.find((f) => f.title === e.target.value)
                                                if (find.id !== "DF1") {
                                                    copy[i].value = find;
                                                }
                                            } else {
                                                copy[i].value = e.target.value;
                                            }
                                            setInformation(copy);
                                        }}
                                    >
                                        {v?.options?.map((nV, nI) => (
                                            <MenuItem key={nI} value={nV.title} >
                                                {nV.title}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                    {v.value === "Organization Funded" ? (
                                        <>
                                            {/* render organizations */}
                                            <TextField
                                                select={true}
                                                label={selectOrganization.title}
                                                sx={{ m: 1, width: screenWidth, background: "#fff", }}
                                                value={selectOrganization.value.title}
                                                onChange={(e) => {
                                                    const copy = { ...selectOrganization };
                                                    const find = copy?.options?.find((f) => f.title === e.target.value)
                                                    if (find.id !== "D1") {
                                                        copy.value = find;
                                                        setSelectOrganization(copy);
                                                    }
                                                }}
                                            >
                                                {selectOrganization.options?.map((value) => (
                                                    <MenuItem key={value?.title} value={value?.title}>
                                                        <Typography textAlign="left">{value?.title}</Typography>
                                                    </MenuItem>
                                                ))}
                                            </TextField>
                                        </>
                                    ) : null}
                                </div>
                            ) : null}
                        </div>
                    )
                })}
            </Box>
        </>
    )
}

export const RoasterInfo = ({ screenWidth }) => {
    const [roaster, setRoaster] = useState([
        {
            title: "Employee",
            value: "Dr. Shamsa Naz",
            width: screenWidth,
            type: "drop_down",
            required: true,
            options: [
                {
                    title: "Dr. Shamsa Naz",
                    selected: true
                },
                {
                    title: "Dr. Amir Hussain",
                    selected: false
                },
                {
                    title: "Dr. Ahsan Bashir",
                    selected: false
                },
                {
                    title: "Dr. Amberin Saadat",
                    selected: false
                },
                {
                    title: "Dr. Saba Shaukat",
                    selected: false
                },
                {
                    title: "Dr. Haroon Akthar",
                    selected: false
                },
                {
                    title: "Dr. Junaid Mughal",
                    selected: false
                },

            ]
        },
        {
            title: "Start Time",
            value: dayjs('2022-04-17T08:30'),
            width: screenWidth,
            required: true,
            type: "timePicker"
        },
        {
            title: "End Time",
            value: dayjs('2022-04-17T15:30'),
            width: screenWidth,
            required: true,
            type: "timePicker"
        },
        {
            title: "Total Hours",
            value: 0,
            width: screenWidth,
            required: true,
            disabled: true,
            type: "number"
        },
        {
            title: "Break Hours",
            value: 0,
            width: screenWidth,
            required: true,
            disabled: false,
            type: "number"
        },
    ])
    return (
        <>
            <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
                {roaster.map((v, i) => {
                    return (
                        <div key={i}>
                            {v.type === "text" || v.type === "number" ? (
                                <TextField
                                    style={{ marginBottom: "2%" }}
                                    type={v.type}
                                    key={i}
                                    required={v.required}
                                    label={v.title}
                                    htmlFor={v.title}
                                    defaultValue={v.value}
                                    disabled={v.disabled}
                                    sx={{ m: 1, width: v.width }}
                                />
                            ) : null}
                            {v.type === "timePicker" ? (
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <MobileTimePicker
                                        label={v.title}
                                        value={v.value}
                                        sx={{ m: 1, width: v.width }}
                                    // onChange={(e) => changeStartTimeSlot(i, nI, e)}
                                    />
                                </LocalizationProvider>
                            ) : null}
                            {v.type === "drop_down" ? (
                                <TextField
                                    select={true}
                                    label={v.title}
                                    htmlFor={v.title}
                                    key={i}
                                    required={v.required}
                                    sx={{ m: 1, width: v.width }}
                                    defaultValue={v.value}
                                // helperText={"Please select your " + v.title}
                                >
                                    {v?.options?.map((option) => (
                                        <MenuItem key={option.title} value={option.title}>
                                            {option.title}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            ) : null}
                        </div>
                    )
                })}
            </Box>
        </>
    )
}

const WeekdayChecklist = ({ checkedDays, handleChange, weekdays }) => {
    return (
        <Grid container spacing={2} justifyContent="center">
            <Grid item xs={12} sx={{ mx: 1, }}>
                <Typography color={"secondary"} sx={{ textTransform: "capitalize", fontFamily: 'Roboto-Medium', }}>Select Weekdays:</Typography>
            </Grid>
            <Grid item xs={12} sx={{ mx: 1, }}>
                <FormGroup row>
                    {weekdays.map((day) => (
                        <FormControlLabel
                            key={day}
                            control={
                                <Checkbox
                                    checked={checkedDays[day]}
                                    onChange={handleChange}
                                    name={day}
                                    color="primary"
                                />
                            }
                            label={day}
                        />
                    ))}
                </FormGroup>
            </Grid>
        </Grid>
    );
};

const DaysInfo = ({ screenWidth, visitDaysData, setVisitDaysData }) => {
    const [checkedDays, setCheckedDays] = useState({
        Monday: false,
        Tuesday: false,
        Wednesday: false,
        Thursday: false,
        Friday: false,
        Saturday: false,
        Sunday: false,
    });
    // List of weekdays
    const weekdays = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"];
    // Handle change when a checkbox is clicked
    const handleChange = (event) => {
        setCheckedDays({
            ...checkedDays,
            [event.target.name]: event.target.checked,
        });
    };
    const [selectedOption, setSelectedOption] = useState("");
    // // for selection
    const [timeFields, setTimeFields] = useState([
        {
            title: "Start Time",
            value: "12:00",
            width: screenWidth,
            type: "time",
            required: true,
        },
        {
            title: "End Time",
            value: "12:00",
            width: screenWidth,
            type: "time",
            required: true,
        },
    ]);
    const [multipleTimeFields, setMultipleTimeFields] = useState([]);
    const [actualDates, setActualDates] = useState([]);
    const [datesList, setDatesList] = useState([
        {
            title: "Start Date",
            value: dayjs(new Date()),
            width: screenWidth,
            type: "date",
            required: true,
            disabled: false,
        },
        {
            title: "End Date",
            value: null,
            width: screenWidth,
            type: "date",
            required: false,
            disabled: false,
        },
    ]);
    const [continueWithoutEndDate, setContinueWithoutEndDate] = useState(false);
    const [continueWithoutEndDateWeekly, setContinueWithoutEndDateWeekly] = useState(false);
    const [continueShifts7Weeks, setContinueShifts7Weeks] = useState(null)
    const [xDaysList, setxDaysList] = useState([]);
    const [xDaysNumber, setxDaysNumber] = useState(0);
    const [sameAsSelected, setSamAsSelected] = useState(true)
    // // // the final object
    const [finalObject, setFinalObject] = useState({
        recurring_type: "", // selected type add here
        selected_days: null, // it is going to be array of days with week-day and time
        x_day: 0, // it is the number of gap in selected days
        x_days: null, // it is the number of gap in selected days
        week_days: null, // week days data with yes/no
        start_date: "",
        end_date: "",
        start_time: "",
        end_time: "",
    })

    const handleClick = (index) => {
        // setOpen(!open);
        const copy = [...multipleTimeFields];
        copy[index].collapse = !copy[index].collapse
        setMultipleTimeFields(copy);
    };
    // // // save date state
    const [serviceTimeOption, setServiceTimeOption] = useState([
        {
            title: "Recurring Type",
            value: "Please Select Type",
            width: screenWidth,
            type: "drop_down",
            required: true,
            disabled: false,
            options: [
                {
                    title: "Please Select Type",
                    selected: true
                },
                {
                    title: "Select Actual Dates",
                    selected: true
                },
                {
                    title: "Use Weekly Recurring",
                    selected: false
                },
                {
                    title: "Use Every X Days Recurring",
                    selected: false
                },
            ]
        },
        {
            title: "Every X Days Where X Is",
            value: 0,
            width: screenWidth,
            type: "number",
            required: true,
            disabled: false,
        },
    ]);

    const [workDays, setWorkDays] = useState([
        // {
        //     title: "Monday",
        //     value: "Yes",
        //     width: screenWidth,
        //     type: "drop_down",
        //     required: true,
        //     options: [
        //         {
        //             title: "Yes",
        //             selected: true
        //         },
        //         {
        //             title: "No",
        //             selected: true
        //         },
        //     ],
        //     start_time: {
        //         title: "Start Time",
        //         value: "12:00",
        //         width: screenWidth,
        //         type: "time",
        //         required: true,
        //     },
        //     end_time: {
        //         title: "End Time",
        //         value: "12:00",
        //         width: screenWidth,
        //         type: "time",
        //         required: true,
        //     }
        // },
        // {
        //     title: "Tuesday",
        //     value: "Yes",
        //     width: screenWidth,
        //     type: "drop_down",
        //     required: true,
        //     options: [
        //         {
        //             title: "Yes",
        //             selected: true
        //         },
        //         {
        //             title: "No",
        //             selected: true
        //         },
        //     ],
        //     start_time: {
        //         title: "Start Time",
        //         value: "12:00",
        //         width: screenWidth,
        //         type: "time",
        //         required: true,
        //     },
        //     end_time: {
        //         title: "End Time",
        //         value: "12:00",
        //         width: screenWidth,
        //         type: "time",
        //         required: true,
        //     }
        // },
        // {
        //     title: "Wednesday",
        //     value: "Yes",
        //     width: screenWidth,
        //     type: "drop_down",
        //     required: true,
        //     options: [
        //         {
        //             title: "Yes",
        //             selected: true
        //         },
        //         {
        //             title: "No",
        //             selected: true
        //         },
        //     ],
        //     start_time: {
        //         title: "Start Time",
        //         value: "12:00",
        //         width: screenWidth,
        //         type: "time",
        //         required: true,
        //     },
        //     end_time: {
        //         title: "End Time",
        //         value: "12:00",
        //         width: screenWidth,
        //         type: "time",
        //         required: true,
        //     }
        // },
        // {
        //     title: "Thursday",
        //     value: "Yes",
        //     width: screenWidth,
        //     type: "drop_down",
        //     required: true,
        //     options: [
        //         {
        //             title: "Yes",
        //             selected: true
        //         },
        //         {
        //             title: "No",
        //             selected: true
        //         },
        //     ],
        //     start_time: {
        //         title: "Start Time",
        //         value: "12:00",
        //         width: screenWidth,
        //         type: "time",
        //         required: true,
        //     },
        //     end_time: {
        //         title: "End Time",
        //         value: "12:00",
        //         width: screenWidth,
        //         type: "time",
        //         required: true,
        //     }
        // },
        // {
        //     title: "Friday",
        //     value: "Yes",
        //     width: screenWidth,
        //     type: "drop_down",
        //     required: true,
        //     options: [
        //         {
        //             title: "Yes",
        //             selected: true
        //         },
        //         {
        //             title: "No",
        //             selected: true
        //         },
        //     ],
        //     start_time: {
        //         title: "Start Time",
        //         value: "12:00",
        //         width: screenWidth,
        //         type: "time",
        //         required: true,
        //     },
        //     end_time: {
        //         title: "End Time",
        //         value: "12:00",
        //         width: screenWidth,
        //         type: "time",
        //         required: true,
        //     }
        // },
        // {
        //     title: "Saturday",
        //     value: "Yes",
        //     width: screenWidth,
        //     type: "drop_down",
        //     required: true,
        //     options: [
        //         {
        //             title: "Yes",
        //             selected: true
        //         },
        //         {
        //             title: "No",
        //             selected: true
        //         },
        //     ],
        //     start_time: {
        //         title: "Start Time",
        //         value: "12:00",
        //         width: screenWidth,
        //         type: "time",
        //         required: true,
        //     },
        //     end_time: {
        //         title: "End Time",
        //         value: "12:00",
        //         width: screenWidth,
        //         type: "time",
        //         required: true,
        //     }
        // },
        // {
        //     title: "Sunday",
        //     value: "Yes",
        //     width: screenWidth,
        //     type: "drop_down",
        //     required: true,
        //     options: [
        //         {
        //             title: "Yes",
        //             selected: true
        //         },
        //         {
        //             title: "No",
        //             selected: true
        //         },
        //     ],
        //     start_time: {
        //         title: "Start Time",
        //         value: "12:00",
        //         width: screenWidth,
        //         type: "time",
        //         required: true,
        //     },
        //     end_time: {
        //         title: "End Time",
        //         value: "12:00",
        //         width: screenWidth,
        //         type: "time",
        //         required: true,
        //     }
        // },
    ]);

    const [xDaysDuration, setxDaysDuration] = useState([
        {
            title: "Start Date",
            value: dayjs(new Date()),
            width: screenWidth,
            type: "date",
            required: true,
            disabled: false
        },
        {
            title: "End Date",
            value: null,
            width: screenWidth,
            type: "date",
            required: false,
            disabled: false
        },
        {
            title: "Start Time",
            value: "12:00",
            width: screenWidth,
            type: "time",
            required: true,
            disabled: false
        },
        {
            title: "End Time",
            value: "12:00",
            width: screenWidth,
            type: "time",
            required: false,
            disabled: false
        }
    ])

    const generateXDayObjects = (startDate, endDate, startTime, endTime, xDayInterval) => {
        const result = [];
        const currentDate = new Date(startDate);
        const endDateObj = new Date(endDate);

        while (currentDate <= endDateObj) {
            // Format date and time
            const dateString = moment(currentDate).format("YYYY-MM-DD");
            const dayOfWeek = currentDate.toLocaleString('default', { weekday: 'long' });

            // Push object with date, day, start time, and end time
            result.push({
                date: dateString,
                dayOfWeek: dayOfWeek,
                startTime: startTime,
                endTime: endTime,
                xDay: xDayInterval
            });

            // Move to the next X day interval
            currentDate.setDate(currentDate.getDate() + xDayInterval);
        }

        return result;
    }

    const calculateDateGap = (startDate, endDate) => {
        // Convert input strings to Date objects
        const start = new Date(startDate);
        const end = new Date(endDate);

        // Ensure the start date is before or the same as the end date
        if (start > end) {
            throw new Error("Start date must be before or the same as end date");
        }

        // Set the time portion of both dates to midnight to ignore time
        start.setHours(0, 0, 0, 0);
        end.setHours(0, 0, 0, 0);

        // Calculate the difference in milliseconds
        const diffTime = end - start;

        // Convert milliseconds to days
        const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

        return diffDays;
    }

    const calculateHourGap = (startDateTime, endDateTime) => {
        // Convert input strings to Date objects
        const start = new Date(startDateTime);
        const end = new Date(endDateTime);

        // Ensure the start date is before or the same as the end date
        if (start > end) {
            throw new Error("Start date and time must be before or the same as end date and time");
        }

        // Calculate the difference in milliseconds
        const diffTime = end - start;

        // Convert milliseconds to hours
        const diffHours = Math.ceil(diffTime / (1000 * 60 * 60));

        return diffHours;
    }

    useEffect(() => {
        // console.log("why is moving forwad")
        const copy = { ...finalObject };
        copy.recurring_type = selectedOption;
        if (selectedOption?.includes("Dates")) {
            // console.log("why is moving Dates")
            const array = []
            for (let key in actualDates) {
                let obj = {
                    day: actualDates[key]?.day,
                    month: actualDates[key]?.month?.number,
                    year: actualDates[key]?.year,
                    weekDay: actualDates[key]?.weekDay?.name,
                    // startTime: "12:00:00",
                    endTime: "12:00:00",
                }
                array.push(obj)
            }
            copy.start_date = actualDates[0]?.format();
            copy.end_date = actualDates[actualDates.length - 1]?.format();
            const sortByDate = (a, b) => {
                return a.day - b.day;
            };
            copy.selected_days = array;
            copy.selected_days.sort(sortByDate);
        }
        if (selectedOption?.includes("Weekly")) {
            // console.log("why is moving Weekly")
            const arrayWeeks = [];
            for (const key in workDays) {
                arrayWeeks.push({
                    day: workDays[key].day.value,
                    date: workDays[key].date.value,
                    start_time: workDays[key].start_time.value,
                    end_time: workDays[key].end_time.value,
                })
            }
            copy.x_day = 0;
            copy.week_days = arrayWeeks;
            copy.start_date = datesList[0]?.value?.$y + "-" + `${datesList[0]?.value?.$M + 1}` + "-" + datesList[0]?.value?.$D;
            copy.end_date = datesList[1]?.value ? datesList[1]?.value?.$y + "-" + `${datesList[1]?.value?.$M + 1}` + "-" + datesList[1]?.value?.$D : continueShifts7Weeks && moment(continueShifts7Weeks).format("DD-MM-YYYY");
        }
        // console.log("copy", copy)
        setVisitDaysData(copy)
    }, [selectedOption, actualDates, datesList, workDays]);

    useEffect(() => {
        const strt_date = xDaysDuration[0]?.value?.$y + "-" + `${xDaysDuration[0]?.value?.$M + 1}` + "-" + xDaysDuration[0]?.value?.$D;
        const end_date = xDaysDuration[1]?.value?.$y + "-" + `${xDaysDuration[1]?.value?.$M + 1}` + "-" + xDaysDuration[1]?.value?.$D;
        if (selectedOption?.includes("Every") && strt_date && end_date) {
            const copy = { ...finalObject };
            copy.recurring_type = selectedOption;
            copy.start_date = strt_date;
            copy.end_date = end_date;
            copy.start_time = xDaysDuration[2]?.value;
            copy.end_time = xDaysDuration[3]?.value;
            copy.x_day = xDaysNumber;

            const strt_date1 = `${xDaysDuration[0]?.value?.$M + 1}` + "-" + xDaysDuration[0]?.value?.$D + "-" + xDaysDuration[0]?.value?.$y;
            const end_date1 = `${xDaysDuration[1]?.value?.$M + 1}` + "-" + xDaysDuration[1]?.value?.$D + "-" + xDaysDuration[1]?.value?.$y;

            const findGap = calculateDateGap(strt_date1, end_date1);
            const startDateTime = moment(strt_date1).format("YYYY-MM-DD") + "T" + xDaysDuration[2]?.value;
            const endDateTime = moment(end_date1).format("YYYY-MM-DD") + "T" + xDaysDuration[3]?.value;
            const findHoursGap = calculateHourGap(startDateTime, endDateTime);

            if (xDaysNumber >= 1 && findGap >= xDaysNumber && findHoursGap >= 48) {
                copy.x_days = generateXDayObjects(strt_date1, end_date1, xDaysDuration[2]?.value, xDaysDuration[3]?.value, Number(xDaysNumber));
            }
            setVisitDaysData(copy)
        }
    }, [selectedOption, xDaysDuration, xDaysNumber])

    useEffect(() => {
        if (actualDates) {
            for (const key in actualDates) {
                const copy = [...multipleTimeFields]
                const find = copy?.find((e) => e.selected_day === actualDates[key].format())
                if (!find) {
                    copy.push({
                        selected_day: actualDates[key].format(),
                        selected_Time_slot: [_.cloneDeep(timeFields)],
                        collapse: false
                    })
                }
                setMultipleTimeFields(copy)
            }
        }
    }, [actualDates]);

    useEffect(() => {
        if (multipleTimeFields.length !== actualDates.length) {
            const copy = [...multipleTimeFields];
            const filter = []
            for (const key in actualDates) {
                const find = copy?.find((e) => e.selected_day === actualDates[key]?.format())
                if (find) {
                    filter.push(find)
                }
            }
            setMultipleTimeFields(filter)
        }
    }, [multipleTimeFields])

    const handleKeyDown = (event) => {
        if (event.target.value.length > 2) {
            event.preventDefault(); // Prevent inputting minutes
        }
    };

    const handleActualDateTimeSlotChange = (value, i, iN, iK) => {
        const copy = [...multipleTimeFields];
        const match = value.match(/^(\d{2})/)
        copy[i].selected_Time_slot[iN][iK].value = match[1] + ":00";

        if (sameAsSelected) {
            for (const key in copy) {
                copy[key].selected_Time_slot[iN][iK].value = match[1] + ":00";
            }
        }

        const copyObj = { ...visitDaysData }
        const copyArray = [...copyObj.selected_days]
        for (const key in copyArray) {
            const date = monthsList[copyArray[key].month - 1] + "-" + copyArray[key].day + "-" + copyArray[key].year;
            if (date === copy[i].selected_day && !sameAsSelected) {
                if (copy[i].selected_Time_slot[iN][iK].title === "Start Time") {
                    copyArray[key].startTime = match[1] + ":00:00"
                }
                if (copy[i].selected_Time_slot[iN][iK].title === "End Time") {
                    copyArray[key].endTime = match[1] + ":00:00"
                }
            }
            if (sameAsSelected) {
                if (copy[i].selected_Time_slot[iN][iK].title === "Start Time") {
                    copyArray[key].startTime = match[1] + ":00:00"
                }
                if (copy[i].selected_Time_slot[iN][iK].title === "End Time") {
                    copyArray[key].endTime = match[1] + ":00:00"
                }
            }
        }
        // console.log("copyObj", copyObj)
        setMultipleTimeFields(copy)
        setVisitDaysData(copyObj)
    }

    const calculateSevenWeeks = (startDate) => {
        // Ensure the startDate is a Date object
        let start = new Date(startDate);

        if (isNaN(start)) {
            throw new Error("Invalid start date");
        }

        // Array to store the 7 weekly dates
        let dates = [];

        // Push the start date into the dates array
        dates.push(start);

        // Calculate and push the dates for the next 6 weeks
        for (let i = 1; i <= 7; i++) {
            let nextDate = new Date(start);
            nextDate.setDate(start.getDate() + i * 7);
            dates.push(nextDate);
        }

        return dates;
    }

    useEffect(() => {

        const generateWeeklySchedule = (startDate, endDate, days) => {
            // Ensure days is an array
            if (!Array.isArray(days)) {
                days = [days]; // Convert to an array if it's a single string
            }

            const schedule = [];
            const startDateObj = new Date(startDate);
            const shiftsPerDay = 7; // Create 7 shifts per day when no endDate is provided
            const dayCounter = {}; // To count the occurrences of each day

            // If endDate is null, we will calculate it for 7 weeks
            let endDateObj = endDate ? new Date(endDate) : null;

            // Generate shifts for each day in the array
            days.forEach((day) => {
                let currentDate = getFirstOccurrenceOfDay(startDate, day);
                let createdShifts = 0;

                while (true) {
                    // If an endDate is provided, break when we exceed it
                    if (endDateObj && currentDate > endDateObj) {
                        break;
                    }

                    // If no endDate is provided, stop when we've created 7 shifts per day
                    if (!endDateObj && createdShifts >= shiftsPerDay) {
                        break;
                    }

                    // Extract the day of the week and format the date
                    const formattedDay = currentDate.toLocaleString('en-US', { weekday: 'long' });
                    // const date = currentDate.toISOString().split('T')[0]; // YYYY-MM-DD format
                    const date = moment(currentDate).format("YYYY-MM-DD"); // YYYY-MM-DD format

                    // Count the occurrences of each day
                    if (!dayCounter[formattedDay]) {
                        dayCounter[formattedDay] = 0;
                    }
                    dayCounter[formattedDay]++;

                    // Add shift to the schedule with or without the `repeated` flag
                    schedule.push({
                        day: {
                            title: "Shift Day",
                            value: day,
                            width: screenWidth,
                            type: "text",
                            required: false,
                            disabled: true,
                            repeated: dayCounter[formattedDay] > 1
                        },
                        date: {
                            title: "Shift Date",
                            value: date,
                            width: screenWidth,
                            type: "text",
                            required: false,
                            disabled: true
                        },
                        start_time: {
                            title: "Shift Start Time",
                            value: "12:00:00",
                            width: screenWidth,
                            type: "time",
                            required: true,
                            disabled: false
                        },
                        end_time: {
                            title: "Shift End Time",
                            value: "12:00:00",
                            width: screenWidth,
                            type: "time",
                            required: true,
                            disabled: false
                        }
                    });

                    // Increment the created shifts count
                    createdShifts++;

                    // Increment by 7 days to ensure the next shift occurs exactly 7 days later
                    currentDate.setDate(currentDate.getDate() + 7);

                    // If we exceed the end date, exit the loop
                    if (endDateObj && currentDate > endDateObj) {
                        break;
                    }
                }
            });

            // Sort the schedule by date
            schedule.sort((a, b) => new Date(a.date) - new Date(b.date));

            return schedule;
        }

        // Helper function to find the first occurrence of a day
        const getFirstOccurrenceOfDay = (startDate, day) => {
            const date = new Date(startDate);
            const dayNumber = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'].indexOf(day);

            // Find the first occurrence of the specified day of the week after or on the startDate
            while (date.getDay() !== dayNumber) {
                date.setDate(date.getDate() + 1);
            }

            return date;
        }

        const startDate = datesList[0].value; // Start date (YYYY-MM-DD)
        const endDate = datesList[1].value;   // End date (YYYY-MM-DD)
        const day = weekdays.filter((day) => checkedDays[day]);

        if (startDate && day) {
            try {
                const schedule = generateWeeklySchedule(startDate, endDate, day);
                // console.log("schedule", schedule)
                if (schedule.length === 0) {
                    setWorkDays([]);
                    setContinueShifts7Weeks(null)
                } else {
                    setWorkDays(schedule);
                }
            } catch (error) {
                console.error(error.message);
            }
        }
    }, [datesList, checkedDays, continueWithoutEndDateWeekly])

    const handleToggleChangeWeekly = (event) => {
        setContinueWithoutEndDateWeekly(event.target.checked);
        const copy = [...datesList];
        copy[1].disabled = event.target.checked;
        const continueDate = calculateSevenWeeks(datesList[0].value);
        setContinueShifts7Weeks(continueDate[continueDate.length - 1]);
        if (copy[1].disabled) {
            copy[1].value = dayjs(continueDate[continueDate.length - 1]);
        } else {
            copy[1].value = null;
            setContinueShifts7Weeks(null)
        }
        setDatesList(copy);
    };

    const handleToggleChange = (event) => {
        setContinueWithoutEndDate(event.target.checked);
        // if (event.target.checked) {
        const copy = [...xDaysDuration]
        copy[1].disabled = event.target.checked;
        const continueDate = calculateSevenWeeks(xDaysDuration[0].value);
        setContinueShifts7Weeks(continueDate[continueDate.length - 1]);
        if (copy[1].disabled) {
            copy[1].value = dayjs(continueDate[continueDate.length - 1]);
        } else {
            copy[1].value = dayjs(new Date())
        }
        setxDaysDuration(copy)
        // }
    };

    const theWeeklyEndDate = datesList[1]?.value ? datesList[1]?.value?.$D + "/" + `${datesList[1]?.value?.$d.getMonth() + 1}` + "/" + datesList[1]?.value?.$y : continueShifts7Weeks && moment(continueShifts7Weeks).format("DD-MM-YYYY");
    const theXdayEndDate = xDaysDuration[1]?.value ? xDaysDuration[1]?.value?.$D + "/" + `${xDaysDuration[1]?.value?.$d.getMonth() + 1}` + "/" + xDaysDuration[1]?.value?.$y : continueShifts7Weeks && moment(continueShifts7Weeks).format("DD-MM-YYYY");

    // console.log(visitDaysData)
    return (
        <Grid container >
            <Grid item={true} xs={12} sm={12} md={12} lg={12} sx={{ display: 'flex', }}>
                <Box>
                    {serviceTimeOption.map((v, i) => {
                        if (v.type === "drop_down") {
                            return (
                                <TextField
                                    select={true}
                                    label={v.title}
                                    htmlFor={v.title}
                                    key={i}
                                    required={v.required}
                                    sx={{ mx: 1, width: v.width }}
                                    value={v.value}
                                    onChange={(e) => {
                                        if (e.target.value !== "Please Select Type") {
                                            const copy = [...serviceTimeOption];
                                            copy[i].value = e.target.value;
                                            setSelectedOption(e.target.value)
                                            setServiceTimeOption(copy);
                                        }
                                    }}
                                >
                                    {v?.options?.map((option) => (
                                        <MenuItem key={option.title} value={option.title}>
                                            {option.title}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            )
                        }
                        if (v.type === "number" && selectedOption.includes("Every")) {
                            return (
                                <TextField
                                    type={v.type}
                                    key={i}
                                    required={v.required}
                                    label={v.title}
                                    htmlFor={v.title}
                                    value={xDaysNumber}
                                    inputProps={{
                                        step: 1, // Prevents decimals
                                        min: 0,  // Example to restrict minimum value, can be customized
                                    }}
                                    sx={{ mx: 1, width: v.width }}
                                    onKeyDown={(event) => {
                                        if (event.key === '.' || event.key === 'e' || event.key === 'E') {
                                            event.preventDefault();
                                        }
                                    }}
                                    onChange={(e) => {
                                        setxDaysNumber(e.target.value);
                                    }}
                                />
                            )
                        }
                    })}
                </Box>
            </Grid>
            {selectedOption?.includes("Dates") && (
                <Grid container sx={{ display: 'flex', justifyContent: "center" }}>
                    <Grid item={true} xs={12} sm={12} md={12} lg={12} sx={{ m: 1, }}>
                        <Typography color={"secondary"} sx={{ textTransform: "capitalize", fontFamily: 'Roboto-Medium', }}>
                            Please Select Start Date and End Date
                        </Typography>
                    </Grid>
                    <Grid item={true} xs={12} sm={12} md={12} lg={12} sx={{
                        display: "flex",
                        justifyContent: "center"
                    }}>

                        <Calendar
                            value={actualDates}
                            onChange={setActualDates}
                            multiple
                            disableMonthPicker
                            disableYearPicker
                            format={"MMM-DD-YYYY"}
                            // minDate={new Date()}
                            mapDays={({ date }) => {
                                let props = {}
                                let isWeekend = [0, 6].includes(date.weekDay.index)
                                if (isWeekend) props.className = "highlight highlight-red"
                                return props
                            }}
                            numberOfMonths={window.screen.width < 900 ? 1 : 3}
                        />
                    </Grid>
                </Grid>
            )}
            {selectedOption?.includes("Weekly") && (
                <Grid container sx={{ display: 'flex', justifyContent: "center" }}>
                    <Grid item={true} xs={12} sm={12} md={12} lg={12} sx={{ m: 1, }}>
                        <Typography color={"secondary"} sx={{ textTransform: "capitalize", fontFamily: 'Roboto-Medium', }}>
                            Please Select Start Date and End Date
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sx={{ mx: 1, }}>
                        <FormControlLabel
                            control={
                                <Switch
                                    checked={continueWithoutEndDateWeekly}
                                    onChange={handleToggleChangeWeekly}
                                />
                            }
                            label="Continue service without end date"
                        />
                    </Grid>
                    <Grid item={true} xs={12} sm={12} md={12} lg={12} >
                        {datesList.map((v, i) => {
                            if (v.type === "date") {
                                return (
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <DatePicker
                                            // minDate={new Date()}
                                            format="DD-MM-YYYY"
                                            views={["day", "month", "year"]}
                                            key={i + randomIds()}
                                            label={v.title}
                                            disabled={v.disabled}
                                            value={v.value}
                                            sx={{ m: 1, width: v.width }}
                                            onChange={(newValue) => {
                                                // console.log("newValue", newValue)
                                                const copy = [...datesList];
                                                copy[i].value = newValue;
                                                setDatesList(copy);
                                            }}
                                            renderInput={(params) => <TextField {...params} fullWidth />}
                                        />
                                    </LocalizationProvider>
                                )
                            }
                        })}
                    </Grid>
                    {datesList[1].value && (
                        <WeekdayChecklist checkedDays={checkedDays} handleChange={handleChange} weekdays={weekdays} />
                    )}
                    {/* {workDays.length && datesList[1].value ? (
                        <Grid item={true} xs={12} sm={12} md={12} lg={12} sx={{ m: 1, }}>
                            <Typography color={"secondary"} sx={{ textTransform: "capitalize", fontFamily: 'Roboto-Medium', }}>
                                Please Select Time Slot
                            </Typography>
                        </Grid>
                    ) : null} */}
                    <Grid item={true} xs={12} sm={12} md={12} lg={12} >
                        {/* {workDays.length && datesList[1].value ? (
                            <Box sx={{ mx: 1, }}>
                                <FormControlLabel
                                    sx={{ width: screenWidth, mx: 2 }}
                                    control={
                                        <Switch checked={sameAsSelected} onChange={(e) => setSamAsSelected(e.target.checked)} name="same time" />
                                    }
                                    label="Apply the selected time to all."
                                />
                            </Box>
                        ) : null} */}
                        <Box sx={{ mx: 1, }}>
                            <List sx={{ width: '100%', maxWidth: window.screen.width, bgcolor: 'background.paper' }}>
                                {datesList[1].value && workDays?.map((v, i) => {
                                    if (!v?.day?.repeated) {
                                        return (
                                            <ListItem key={i + randomIds()} sx={{ py: 2, display: "flex", flexDirection: { lg: "row", xs: "column", md: "row", sm: "column" }, }}>
                                                <TextField
                                                    type={v?.day?.type}
                                                    label={v?.day?.title}
                                                    htmlFor={v?.day?.title}
                                                    required={v?.day?.required}
                                                    disabled={v?.day?.disabled}
                                                    sx={{ mx: 1, width: { xs: v?.day?.width, md: v?.day?.width / 2, lg: v?.day?.width } }}
                                                    value={v?.day?.value}
                                                />
                                                {/* <TextField
                                                    type={v?.date?.type}
                                                    label={v?.date?.title}
                                                    htmlFor={v?.date?.title}
                                                    required={v?.date?.required}
                                                    disabled={v?.date?.disabled}
                                                    sx={{ mx: 1, width: { xs: v?.date?.width, md: v?.date?.width / 2, lg: v?.date?.width } }}
                                                    value={v?.date?.value}
                                                /> */}
                                                <TextField
                                                    label={v?.start_time?.title}
                                                    type={v?.start_time?.type}
                                                    value={v?.start_time?.value}
                                                    sx={{ mx: 1, width: v?.start_time?.width }}
                                                    onChange={(e) => {
                                                        const change = [...workDays]
                                                        const match = e.target.value.match(/^(\d{2})/)
                                                        // console.log(change[i].start_time.value)
                                                        if (sameAsSelected) {
                                                            for (let key in change) {
                                                                change[key].start_time.value = match[1] + ":00:00";
                                                            }
                                                        } else {
                                                            change[i].start_time.value = match[1] + ":00:00";
                                                        }
                                                        setWorkDays(change)
                                                    }}
                                                />
                                                <TextField
                                                    label={v?.end_time?.title}
                                                    type={v?.end_time?.type}
                                                    value={v?.end_time?.value}
                                                    sx={{ mx: 1, width: v?.end_time?.width }}
                                                    onChange={(e) => {
                                                        const change = [...workDays]
                                                        const match = e.target.value.match(/^(\d{2})/)
                                                        // console.log(change[i].start_time.value)
                                                        if (sameAsSelected) {
                                                            for (let key in change) {
                                                                change[key].end_time.value = match[1] + ":00:00";
                                                            }
                                                        } else {
                                                            change[i].end_time.value = match[1] + ":00:00";
                                                        }
                                                        setWorkDays(change)
                                                    }}
                                                />
                                            </ListItem>
                                        )
                                    }
                                })}
                            </List>
                        </Box>
                        {workDays.length && datesList[1].value ? (
                            <Box sx={{ mx: 4 }}>
                                <Typography color={"secondary.text3"} sx={{ textTransform: "capitalize", fontFamily: 'Roboto-Regular', }}>
                                    Occur Every {weekdays.filter((day) => checkedDays[day])?.toString()}, Until {theWeeklyEndDate}, Total number of Session is: {workDays?.length}
                                </Typography>
                            </Box>
                        ) : null}
                    </Grid>
                </Grid>
            )}
            {selectedOption?.includes("Every") && xDaysNumber ? (
                <Grid container >
                    <Grid item={true} xs={12} sm={8} md={8} lg={8} sx={{ m: 1, }}>
                        <Typography color={"secondary"} sx={{ textTransform: "capitalize", fontFamily: 'Roboto-Medium', }}>
                            Please select start and end date
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sx={{ mx: 1, }}>
                        <FormControlLabel
                            control={
                                <Switch
                                    checked={continueWithoutEndDate}
                                    onChange={handleToggleChange}
                                />
                            }
                            label="Continue service without end date"
                        />
                    </Grid>
                    <Grid item={true} xs={12} sm={12} md={12} lg={12} >
                        {xDaysDuration.map((v, i) => {
                            if (v.type === "date") {
                                return (
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <DatePicker
                                            // minDate={new Date()}
                                            format="DD-MM-YYYY"
                                            views={["day", "month", "year"]}
                                            key={i + randomIds()}
                                            label={v.title}
                                            disabled={v.disabled}
                                            value={v.value}
                                            sx={{ m: 1, width: v.width }}
                                            onChange={(newValue) => {
                                                const copy = [...xDaysDuration];
                                                copy[i].value = newValue;
                                                setxDaysDuration(copy);
                                            }}
                                            renderInput={(params) => <TextField {...params} fullWidth />}
                                        />
                                    </LocalizationProvider>
                                )
                            }
                        })}
                    </Grid>
                    <Grid item={true} xs={12} sm={8} md={8} lg={8} sx={{ m: 1, }}>
                        <Typography color={"secondary"} sx={{ textTransform: "capitalize", fontFamily: 'Roboto-Medium', }}>
                            Please Select Start and End Time
                        </Typography>
                    </Grid>
                    <Grid item={true} xs={12} sm={12} md={12} lg={12} >
                        {xDaysDuration.map((v, i) => {
                            if (v.type === "time") {
                                return (
                                    <TextField
                                        key={i + randomIds()}
                                        label={v.title}
                                        type={v.type}
                                        value={v.value}
                                        sx={{ m: 1, width: v.width }}
                                        onChange={(e) => {
                                            const change = [...xDaysDuration]
                                            const match = e.target.value.match(/^(\d{2})/)
                                            change[i].value = match[1] + ":00:00";
                                            setxDaysDuration(change)
                                        }}
                                    />
                                )
                            }
                        })}
                    </Grid>
                    {visitDaysData?.x_days?.length && xDaysDuration[1].value ? (
                        <Box sx={{ mx: 1 }}>
                            <Typography color={"secondary.text3"} sx={{ textTransform: "capitalize", fontFamily: 'Roboto-Regular', }}>
                                Occur Until {theXdayEndDate}, Total number of Session is: {visitDaysData?.x_days?.length}
                            </Typography>
                        </Box>
                    ) : null}
                </Grid>
            ) : null}
            {/* selected days grid */}
            <Grid item={true} xs={12} sm={12} md={12} lg={12} >
                {selectedOption?.includes("Dates") && actualDates.length > 0 && (
                    <Grid item={true} xs={12} sm={12} md={12} lg={12} sx={{ m: 1, }}>
                        <Typography color={"primary"} sx={{ textTransform: "capitalize", fontFamily: 'Roboto-Medium', }}>
                            List Of Selelcted Days
                        </Typography>
                    </Grid>
                )}
                <Grid xs={11} sm={11} md={11} lg={11}>
                    {selectedOption?.includes("Dates") && (
                        <Box>
                            <FormControlLabel
                                sx={{ width: screenWidth, mx: 2 }}
                                control={
                                    <Switch checked={sameAsSelected} onChange={(e) => setSamAsSelected(e.target.checked)} name="same time" />
                                }
                                label="Apply the selected time to all."
                            />
                        </Box>
                    )}
                    <List sx={{ width: "100%", maxWidth: window.screen.width, bgcolor: 'background.paper', }}>
                        {selectedOption?.includes("Dates") && actualDates.length > 0 && multipleTimeFields?.map((v, i) => {
                            return (
                                <ListItem component={Paper} elevation={1} key={i + randomIds()} sx={{ my: 1, py: 1, display: "flex", flexDirection: "column", }}>
                                    <ListItemButton onClick={() => {
                                        handleClick(i)
                                    }} sx={{ width: "100%" }}>
                                        <ListItemText primary={v.selected_day} primaryTypographyProps={{
                                            style: {
                                                textTransform: "capitalize",
                                                fontFamily: "Roboto-Bold"
                                            }
                                        }} />
                                        {v.collapse ? <ExpandLess /> : <ExpandMore />}
                                    </ListItemButton>
                                    <Collapse in={v.collapse} sx={{ width: "100%", }}>
                                        <List component="div" disablePadding sx={{ mx: 2 }}>
                                            <ListItemText primary={
                                                <Stack sx={{ alignItems: "center", }} direction={window.screen.width < 900 ? "column" : "row"} spacing={2}>
                                                    {v.selected_Time_slot.map((vN, iN) => {
                                                        return (
                                                            <Box key={iN}>
                                                                {vN.map((vK, iK) => {
                                                                    return (
                                                                        <TextField
                                                                            type="time"
                                                                            key={vK.title + iK}
                                                                            label={vK.title}
                                                                            value={vK.value}
                                                                            required={vK.required}
                                                                            sx={{ m: 1, width: vK.width / 2 }}
                                                                            onKeyDown={handleKeyDown}
                                                                            onChange={(e) => handleActualDateTimeSlotChange(e.target.value, i, iN, iK)}
                                                                        />
                                                                    )
                                                                })}
                                                            </Box>
                                                        )
                                                    })}
                                                    <Button disabled={v.selected_Time_slot.length >= 2} onClick={() => {
                                                        const copy = [...multipleTimeFields];
                                                        copy[i].selected_Time_slot.push(_.cloneDeep(timeFields));
                                                        setMultipleTimeFields(copy);
                                                    }} component="label" startIcon={<Add />}
                                                        variant="outlined" sx={{ py: 1.6, bgcolor: "#ffffff", fontFamily: "Roboto-Medium", textTransform: "capitalize", color: "#000000", border: "2px solid #6ECA35", ":hover": { border: "2px solid #6ECA35", } }}>
                                                        Add
                                                    </Button>
                                                </Stack>
                                            } />
                                        </List>
                                    </Collapse>
                                </ListItem>
                            )
                        })}
                    </List>
                </Grid>
            </Grid>
        </Grid >
    )
}

export const VisitDaysModal = () => {

    const [style, setStyle] = useState({
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        bgcolor: 'background.paper',
        maxWidth: "calc(70%)",
        maxHeight: "calc(85%)",
        minWidth: "calc(70%)",
        boxShadow: 12,
        borderRadius: 1,
        p: 4,
        overflowY: "auto"
    });

    const width = () => {
        if (window.screen.width > 1600) {
            return window.screen.width / 5.2
        }
        if (window.screen.width > 600 && window.screen.width < 1600) {
            return window.screen.width / 4
        } else {
            return window.screen.width / 1.4
        }
    };
    const [screenWidth, setScreenWidth] = useState(width);

    useEffect(() => {
        if (window.screen) {
            setScreenWidth(width);
        }
    }, [window.screen]);

    const _serviceRequest = useSelector((state) => state._service_request.visitDaysList);
    const [open, setOpen] = useState(false);
    const [visitDaysData, setVisitDaysData] = useState([]);
    const dispatch = useDispatch()
    const addNewVisit = () => {
        const copy = [..._serviceRequest];
        copy.push(visitDaysData);
        dispatch(visit_days(copy));
        setOpen(false);
    }
    const deleteVisitFromState = (index) => {
        const copy = [..._serviceRequest];
        const x = copy.splice(index, 1);
        dispatch(visit_days(copy));
    }

    return (
        <Grid container >
            {_serviceRequest?.length > 0 && (
                <Grid item={true} xs={12} sm={12} md={12} lg={12}>
                    <List sx={{ width: '100%', maxWidth: window.screen.width, bgcolor: 'background.paper' }}>
                        {_serviceRequest?.map((v, i) => {
                            return (
                                <ListItem component={Paper} elevation={1} key={i + randomIds()} sx={{ m: 1, }}
                                    secondaryAction={
                                        <Box sx={{ display: "flex", alignItems: "center", }}>
                                            <Box sx={{ display: "flex", mx: 2 }}>
                                                <Typography>Service Type:</Typography>
                                                <Typography sx={{
                                                    fontFamily: "Roboto-Medium"
                                                }}>{v?.recurring_type.includes("weekly") ? "Weekly" : "Selected Days"}</Typography>
                                            </Box>
                                            <Box sx={{ mx: 2 }}>
                                                <Stack direction="row" spacing={2}>
                                                    {/* <Button
                                                        variant="outlined" sx={{ bgcolor: "#ffffff", fontFamily: "Roboto-Medium", textTransform: "capitalize", color: "#000000", border: "2px solid #6ECA35", ":hover": { border: "2px solid #6ECA35", } }}>
                                                        Details
                                                    </Button> */}
                                                    <IconButton onClick={() => deleteVisitFromState(i)}>
                                                        <DeleteIcon />
                                                    </IconButton>
                                                </Stack>
                                            </Box>
                                        </Box>
                                    }
                                >
                                    <ListItemAvatar>
                                        <Avatar>
                                            <Typography sx={{ fontSize: 12 }}>Vist</Typography>
                                        </Avatar>
                                    </ListItemAvatar>
                                    <ListItemText
                                        primary={<Typography>Start Date: {v.start_date}</Typography>}
                                        primaryTypographyProps={{
                                            style: {
                                                textTransform: "capitalize",
                                                fontFamily: "Roboto-Medium"
                                            }
                                        }}
                                        secondary={<Typography sx={{ fontSize: 14 }}>End Date: {v.end_date}</Typography>}
                                    />
                                </ListItem>
                            )
                        })}
                    </List>
                </Grid>
            )}
            <Grid item={true} xs={12} sm={12} md={12} lg={12} sx={{ mx: 1 }}>
                <Button onClick={() => { setOpen(true); }} label={"visit tour"} startIcon={<TourIcon />}
                    variant="outlined" sx={{ bgcolor: "#ffffff", fontFamily: "Roboto-Medium", textTransform: "capitalize", color: "#000000", border: "2px solid #6ECA35", ":hover": { border: "2px solid #6ECA35", } }}>
                    Add Visit
                </Button>
            </Grid>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={open}
                onClose={() => setOpen(false)}
                closeAfterTransition
                slots={{ backdrop: Backdrop }}
                slotProps={{
                    backdrop: {
                        timeout: 500,
                    },
                }}
            >
                <Fade in={open}>
                    <Box sx={style}>
                        <Box>
                            <Typography color={"primary"} sx={{ textTransform: "capitalize", fontFamily: "Roboto-Medium", fontSize: "1.5em" }}>
                                Add New Visit
                            </Typography>
                        </Box>
                        <Box sx={{ py: 2 }}>
                            <DaysInfo
                                screenWidth={screenWidth}
                                visitDaysData={visitDaysData}
                                setVisitDaysData={setVisitDaysData}
                            />
                            <Typography color={"secondary"} sx={{ mx: 1, textTransform: "capitalize", fontFamily: 'Roboto-Medium' }}>
                                Shift Check List
                            </Typography>
                            <ShiftsAndComments screenWidth={screenWidth} />
                        </Box>
                        <Box sx={{
                            display: "flex",
                            justifyContent: "flex-end",
                            p: 3
                        }}>
                            <Stack direction="row" spacing={2}>
                                <Button onClick={() => addNewVisit()}
                                    variant="outlined" sx={{ bgcolor: "#ffffff", fontFamily: "Roboto-Medium", textTransform: "capitalize", color: "#000000", border: "2px solid #6ECA35", ":hover": { border: "2px solid #6ECA35", } }}>
                                    Save
                                </Button>
                                <Button onClick={() => { setOpen(false); }}
                                    variant="outlined" sx={{ bgcolor: "#ffffff", fontFamily: "Roboto-Medium", textTransform: "capitalize", color: "#000000", border: "2px solid #6ECA35", ":hover": { border: "2px solid #6ECA35", } }}>
                                    Cancel
                                </Button>
                            </Stack>
                        </Box>
                    </Box>
                </Fade>
            </Modal>
        </Grid>
    )
}

export const ShiftsAndComments = ({ screenWidth }) => {
    const [shifts, setShifts] = useState([
        {
            title: "Shift Title",
            value: "",
            width: screenWidth,
            type: "text",
            required: true,
            multiline: false
        },
        {
            title: "Shift Comments",
            value: "",
            width: screenWidth,
            type: "text",
            required: true,
            multiline: true
        },
    ])
    const dispatch = useDispatch()
    const [shifitTodo, setShiftToDo] = useState([]);

    const createNewShiftTodo = () => {
        const shiftsCopy = [...shifts];
        const copy = [...shifitTodo];
        copy.push({
            title: shifts[0].value,
            comment: shifts[1].value,
            completed: false,
        })
        setShiftToDo(copy)
        for (const key in shiftsCopy) {
            shiftsCopy[key].value = "";
        }
        setShifts(shiftsCopy)
    }

    useEffect(() => {
        if (shifitTodo) {
            dispatch(shift_check_list(shifitTodo))
        }
    }, [shifitTodo])
    return (
        <Grid container>
            <Grid item={true} xs={12} sm={12} md={12} lg={12} >
                <List sx={{ width: '97%', bgcolor: 'background.paper' }}>
                    {shifitTodo?.map((v, i) => {
                        return (
                            <ListItem component={Paper} elevation={1} key={i} sx={{ m: 1 }}>
                                <ListItemAvatar>
                                    <Avatar>
                                        {/* <AssignmentTurnedInIcon /> */}
                                        {i + 1}
                                    </Avatar>
                                </ListItemAvatar>
                                <ListItemText primary={v.title} secondary={v.comment} primaryTypographyProps={{
                                    style: {
                                        textTransform: "capitalize",
                                        fontFamily: "Roboto-Medium"
                                    }
                                }} />
                            </ListItem>
                        )
                    })}
                </List>
            </Grid>
            <Box sx={{ display: 'flex', flexWrap: 'wrap', alignItems: "center" }}>
                {shifts.map((v, i) => {
                    return (
                        <div key={i}>
                            {v.type === "text" ? (
                                <TextField
                                    multiline={v.multiline}
                                    key={i}
                                    required={v.required}
                                    label={v.title}
                                    htmlFor={v.title}
                                    value={v.value}
                                    sx={{ m: 1, width: v.width }}
                                    onChange={(e) => {
                                        const copy = [...shifts];
                                        copy[i].value = e.target.value;
                                        setShifts(copy)
                                    }}
                                />
                            ) : null}
                        </div>
                    )
                })}
                <Button onClick={() => createNewShiftTodo()} component="label" startIcon={<AddTaskIcon />}
                    variant="outlined" sx={{ py: 1.6, bgcolor: "#ffffff", fontFamily: "Roboto-Medium", textTransform: "capitalize", color: "#000000", border: "2px solid #6ECA35", ":hover": { border: "2px solid #6ECA35", } }}>
                    Add Check List
                </Button>
            </Box>
        </Grid>
    )
}

export const TravelInfo = ({ screenWidth }) => {
    const _client_edit_info = useSelector((state) => state._service_request.editInformation);
    const dispatch = useDispatch()
    const [travel, setTravel] = useState([
        {
            title: "Pay Travel KM",
            value: "Yes",
            width: screenWidth,
            type: "drop_down",
            required: true,
            options: [
                {
                    title: "Yes",
                    selected: true
                },
                {
                    title: "No",
                    selected: false
                },
            ]
        },
    ])
    useEffect(() => {
        if (travel) {
            const copy = [...travel];
            const data = { ..._client_edit_info }
            data.pay_travel = copy[0].value;
            dispatch(edit_information(data));
        }
    }, [travel])

    return (
        <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
            {travel.map((v, i) => {
                return (
                    <TextField
                        select={true}
                        label={v.title}
                        htmlFor={v.title}
                        key={v.title + i}
                        required={v.required}
                        sx={{ m: 1, width: v.width }}
                        value={v.value}
                        // helperText={"Please select your " + v.title}
                        onChange={(e) => {
                            const copy = [...travel];
                            copy[i].value = e.target.value;
                            setTravel(copy)
                        }}
                    >
                        {v?.options?.map((option) => (
                            <MenuItem key={option.title} value={option.title}>
                                {option.title}
                            </MenuItem>
                        ))}
                    </TextField>
                )
            })}
        </Box>
    )
}

export const StaffPreference = ({ screenWidth }) => {
    const dispatch = useDispatch()
    const _information = useSelector((state) => state._service_request.information);
    const _visit_days = useSelector((state) => state._service_request.visitDaysList);
    const _assign_staff_worker = useSelector((state) => state._service_request.assignStaffWorker);
    const [description, setDescription] = useState("");
    const [toggleToVisibaleAllStaff, setToggleToVisibaleAllStaff] = useState(false);
    const [getPlansFiltersById, loading, errors] = ServicePlansFilter();
    const [getWorkingDaysByShiftPref, loading_w] = WorkingDaysByFilters()
    const [getStaffLists, loading_staff] = GetStaffList();
    const [getStaffDetailsByID, loading_details] = GetStaffDetailsWithId()
    // const [staffList, loading_staff] = StaffDetailsList();
    const [modifiedStaffData, setModifiedStaffData] = useState(null)
    const [preferenceStaffData, setPreferenceStaffData] = useState([])
    const [loadingData, setLoadingData] = useState(false)

    const [selectStaff, setSelectStaff] = useState({
        title: "Available Staff Workers For the Service",
        value: {
            title: "Please Select Staff",
            id: "D1"
        },
        options: [
            {
                title: "Please Select Staff",
                id: "D1"
            }
        ]
    })

    const [selectPreferenceStaff, setSelectPreferenceStaff] = useState({
        title: "Available Staff Workers For the Service",
        value: {
            title: "Please Select Preference Staff",
            id: "D1"
        },
        options: [
            {
                title: "Please Select Preference Staff",
                id: "D1"
            }
        ]
    })

    const handleLovsDataById = async (staffList) => {
        try {
            const staffData = _.cloneDeep(staffList)
            // const response_list = await getPlansFiltersById()
            // if (response_list?.data) {
            // const list = response_list?.data?.servicePlans?.data;
            // const unmatchedStaff = staffData.filter(staffMember =>
            //     !list.some(plan => plan.attributes.staff_id.data?.id === staffMember.id)
            // );
            for (const key in staffData) {
                const copy = { ...staffData[key] }
                const respons = await getStaffDetailsByID({
                    variables: {
                        "filter": {
                            "staff_detail_id": {
                                "id": {
                                    "eq": staffData[key]?.id
                                }
                            }
                        },
                        "filters": {
                            "staff_detail_id": {
                                "id": {
                                    "eq": staffData[key]?.id
                                }
                            }
                        }
                    }
                })
                if (respons?.data) {
                    // console.log("respons?.data?.staffAnswerLovsReferences?.data", respons?.data?.staffAnswerLovsReferences?.data)
                    copy.attributes.lovs_answers = respons?.data?.staffAnswerLovsReferences?.data;
                    const respons_work = await getWorkingDaysByShiftPref({
                        variables: {
                            "filter": {
                                "staff_detail_id": {
                                    "id": {
                                        "eq": staffData[key]?.id
                                    }
                                }
                            }
                        }
                    })
                    if (respons_work?.data) {
                        copy.attributes.working_days = respons_work.data.workingDays.data;
                    }

                    setModifiedStaffData(staffData);
                    setLoadingData(false);
                }

                // }
            }
        } catch (error) {
            console.log("error", error)
        }
    }

    const handleStaffsData = async () => {
        try {
            setLoadingData(true)
            const respons_staff = await getStaffLists();
            if (respons_staff?.data) {
                const staffList = respons_staff?.data?.staffDetails?.data;
                handleLovsDataById(staffList)
            }
        } catch (error) {
            console.log("error", error)
        }
    }

    useEffect(() => {
        handleStaffsData()
    }, [])

    useEffect(() => {
        if (modifiedStaffData?.length) {
            const copy = { ...selectStaff }
            for (const key in modifiedStaffData) {
                const data = modifiedStaffData[key]?.attributes
                const find = copy.options.find((e) => e?.id === modifiedStaffData[key]?.id)
                if (!find) {
                    const obj = {
                        title: data?.first_name + " " + data?.last_name,
                        id: modifiedStaffData[key]?.id
                    }
                    copy.options.push(obj)
                }
            }
            setSelectStaff(copy)
        }
        handlePreferanceStaffWorker();
    }, [modifiedStaffData])

    const timeToMinutes = (timeStr) => {
        const [hours, minutes] = timeStr.split(":").map(Number);
        return hours * 60 + minutes;
    }

    const isSlotWithinExactRange = (startTime, endTime, slotStartTime, slotEndTime) => {
        const start = timeToMinutes(startTime);
        const end = timeToMinutes(endTime);
        const slotStart = timeToMinutes(slotStartTime);
        const slotEnd = timeToMinutes(slotEndTime);
        // Check if slot is fully within the time range
        return slotStart >= start && slotEnd <= end;
    }

    const handlePreferanceStaffWorker = () => {
        try {
            const copy = []
            const preference = _information?.preference;
            for (const key in modifiedStaffData) {
                const age = modifiedStaffData[key].attributes?.age;
                const gender = modifiedStaffData[key].attributes.lovs_answers?.find((e) => e.attributes.input_name.toLowerCase() === "gender")?.attributes?.selected_option_value;
                const language_spoken = modifiedStaffData[key].attributes.lovs_answers?.find((e) => e.attributes.input_name.toLowerCase() === "language spoken")?.attributes?.selected_option_value;
                const religion = modifiedStaffData[key].attributes.lovs_answers?.find((e) => e.attributes.input_name.toLowerCase() === "religion")?.attributes?.selected_option_value;
                if (age && gender && language_spoken && religion && preference?.attributes?.age === Number(age) && preference?.attributes?.gender === gender && preference?.attributes?.language_spoken === language_spoken && preference?.attributes?.religion === religion) {
                    const days = modifiedStaffData[key].attributes?.working_days;

                    const matchedClientActualDates = days?.find(days =>
                        _visit_days?.some(plan =>
                            plan?.recurring_type.includes("Actual Dates") && plan?.selected_days.some(day =>
                                day.weekDay === days.attributes.select_day &&
                                isSlotWithinExactRange(
                                    days.attributes.day_start_time.slice(0, 8),
                                    days.attributes.day_end_time.slice(0, 8),
                                    day.startTime, day.endTime
                                )
                            )
                        )
                    );

                    const matchedClientWeeklyRecurring = days?.find(days =>
                        _visit_days?.some(plan =>
                            plan?.recurring_type.includes("Weekly Recurring") && plan?.week_days.some(day =>
                                day.day === days.attributes.select_day &&
                                isSlotWithinExactRange(
                                    days.attributes.day_start_time.slice(0, 8),
                                    days.attributes.day_end_time.slice(0, 8),
                                    day.start_time, day.end_time
                                )
                            )
                        )
                    );

                    const matchedClientXDays = days?.find(days =>
                        _visit_days?.some(plan =>
                            plan?.recurring_type.includes("X Days") && plan?.x_days.some(day =>
                                day.dayOfWeek === days.attributes.select_day &&
                                isSlotWithinExactRange(
                                    days.attributes.day_start_time.slice(0, 8),
                                    days.attributes.day_end_time.slice(0, 8),
                                    day.startTime, day.endTime
                                )
                            )
                        )
                    );

                    if (matchedClientActualDates || matchedClientWeeklyRecurring || matchedClientXDays) {
                        const find = copy.find((e) => e?.id === modifiedStaffData[key]?.id)
                        if (!find) {
                            copy.push(modifiedStaffData[key])
                        }
                    }
                }
            }
            setPreferenceStaffData(copy)
        } catch (error) {
            console.log("handlePreferanceStaffWorker", error)
        }
    }

    useEffect(() => {
        if (preferenceStaffData) {
            const copy = { ...selectPreferenceStaff }
            for (const key in preferenceStaffData) {
                const data = preferenceStaffData[key]?.attributes
                const find = copy.options.find((e) => e?.id === preferenceStaffData[key]?.id)
                if (!find) {
                    const obj = {
                        title: data?.first_name + " " + data?.last_name,
                        id: preferenceStaffData[key]?.id
                    }
                    copy.options.push(obj)
                }
            }
            setSelectPreferenceStaff(copy)
        }
    }, [preferenceStaffData])

    useEffect(() => {
        if (!toggleToVisibaleAllStaff && selectStaff.value.id !== "D1") {
            const copy = {
                title: selectStaff.value.title,
                id: selectStaff.value.id,
                description: description
            }
            dispatch(assign_staff_worker(copy))
        }
        if (toggleToVisibaleAllStaff && selectPreferenceStaff.value.id !== "D1") {
            const copy = {
                title: selectPreferenceStaff.value.title,
                id: selectPreferenceStaff.value.id,
                description: description
            }
            dispatch(assign_staff_worker(copy))
        }
    }, [description, selectStaff, selectPreferenceStaff])


    useEffect(() => {
        if (toggleToVisibaleAllStaff) {
            handlePreferanceStaffWorker()
        }
    }, [toggleToVisibaleAllStaff])

    return (
        <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
            {/* handle toggle to visibale all staff otherwise preferences */}
            <Box sx={{ width: "100%", py: 1 }}>
                <FormControlLabel
                    sx={{ px: 1 }}
                    control={
                        <Switch checked={toggleToVisibaleAllStaff} onChange={(e) => setToggleToVisibaleAllStaff(e.target.checked)} name="all staff" />
                    }
                    label={toggleToVisibaleAllStaff ? "Display Preference Staff Worker" : "Display Available Staff Worker"}
                />
            </Box>
            {/* render available staff list */}
            {loadingData ? (
                <Box sx={{
                    background: "rgba(255,255,255,0.5)",
                    position: "absolute",
                    top: 0,
                    left: 0,
                    width: "100%",
                    height: "100%",
                    zIndex: 10,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center"
                }}>
                    <CircularProgress color="secondary" size={45} />
                </Box>
            ) : (
                <Box sx={{ width: "100%", py: 1 }}>
                    {!toggleToVisibaleAllStaff && (<TextField
                        select={true}
                        label={selectStaff.title}
                        sx={{ m: 1, width: screenWidth, background: "#fff", }}
                        value={selectStaff.value.title}
                        onChange={(e) => {
                            const copy = { ...selectStaff };
                            const find = copy?.options?.find((f) => f.title === e.target.value)
                            if (find.id !== "D1") {
                                copy.value = find;
                                setSelectStaff(copy);
                            }
                        }}
                    >
                        {selectStaff.options?.map((value) => (
                            <MenuItem key={value?.title} value={value?.title}>
                                <Typography textAlign="left">{value?.title}</Typography>
                            </MenuItem>
                        ))}
                    </TextField>)}

                    {toggleToVisibaleAllStaff && (<TextField
                        select={true}
                        label={selectPreferenceStaff.title}
                        sx={{ m: 1, width: screenWidth, background: "#fff", }}
                        value={selectPreferenceStaff.value.title}
                        onChange={(e) => {
                            const copy = { ...selectPreferenceStaff };
                            const find = copy?.options?.find((f) => f.title === e.target.value)
                            if (find.id !== "D1") {
                                copy.value = find;
                                setSelectPreferenceStaff(copy);
                            }
                        }}
                    >
                        {selectPreferenceStaff.options?.map((value) => (
                            <MenuItem key={value?.title} value={value?.title}>
                                <Typography textAlign="left">{value?.title}</Typography>
                            </MenuItem>
                        ))}
                    </TextField>)}
                    {/* render preference staff list */}
                    {/* description for staff */}
                    <TextField
                        type="text"
                        multiline={true}
                        required={false}
                        label={"Description"}
                        htmlFor={"Description"}
                        value={description}
                        sx={{ m: 1, width: screenWidth }}
                        onChange={(e) => {
                            setDescription(e.target.value)
                        }}
                    />
                </Box>
            )}
        </Box>
    )
}