import { Avatar, Box, Button, CircularProgress, Collapse, Grid, IconButton, List, ListItem, ListItemText, Paper, Stack, Switch, Tooltip, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react'
import BorderColorOutlinedIcon from '@mui/icons-material/BorderColorOutlined';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import { useSelector } from 'react-redux';
import { HistoryOfClientService } from '../../../services/client_service';
import { IMAGE } from '../../../config/constant';
import moment from 'moment';
import { GetAllRelationalClientInformationById } from '../../../services/admin_service';
import { GetServiceRequests } from '../../../services/service_request';
import { ServiceShiftFilters, VisitDaysByServiceId } from '../../../services/schedule_service';
import { ServicePlansFilter } from '../../../services/finance_service';

const generateDayTimes = (dateString) => {
    const date = new Date(dateString);
    const dayTimes = [];

    // Set start and end times for the day
    const startTime = new Date(date);
    startTime.setHours(0, 0, 0, 0);

    const endTime = new Date(date);
    endTime.setHours(23, 59, 59, 999);

    while (startTime <= endTime) {
        const hours = startTime.getHours();
        const minutes = startTime.getMinutes();
        const ampm = hours >= 12 ? 'PM' : 'AM';
        const formattedHours = hours % 12 || 12; // Convert to 12-hour format
        const formattedMinutes = minutes < 10 ? '0' + minutes : minutes;
        const dateString = startTime.toISOString().split('T')[0]; // Extract date in YYYY-MM-DD format

        dayTimes.push({
            time: `${formattedHours}:${formattedMinutes} ${ampm}`,
            date: dateString,
            meeting: []
        });

        startTime.setMinutes(startTime.getMinutes() + 30); // Increment by 30 minutes
    }

    return dayTimes;
}

const CleintDetailsViewV2 = () => {

    const _history = useSelector((state) => state._history.client_details_id);
    const [getClientRelationalInfoById, loading_de, error_de] = GetAllRelationalClientInformationById()
    const [getHistoryOfClientService, loading_his, error_his] = HistoryOfClientService();
    const [listOfServiceRequest, loading_r, error_r] = GetServiceRequests();
    const [getVisitDaysByServiceId, loading_visit, error_visit] = VisitDaysByServiceId();
    const [getServicePlansFilter, loading_plan, error_plan] = ServicePlansFilter()
    const [getServiceShiftFilters, loading_shift, error_shif] = ServiceShiftFilters();
    // // // // -------------------------------state------------------------------------------------
    const [clientData, setClientData] = useState(null);
    const [clientDropDownAnswers, setClientDropDownAnswers] = useState(null);
    const [emergencyContactDetails, setEmergencyContactDetails] = useState(null)
    const [medicalInfoData, setMedicalInfoData] = useState(null);
    const [admissionData, setAdmission] = useState(null);
    const [referringDoctorData, setReferringDoctor] = useState(null);
    const [individualRiskData, setIndividualRisk] = useState(null);
    const [homeRiskData, setHomeRiskData] = useState(null);
    const [safetyPlans, setSafteyPlans] = useState(null);
    const [goalsAndNotes, setGoalsAndNotes] = useState(null);
    const [active, setActive] = useState(true);
    const [careDetails, setCareDetails] = useState(null);
    const [review, setReview] = useState(null);
    const [activeService, setActiveService] = useState(null);
    const [serviceRequest, setServiceReuest] = useState(null);
    const [visitDays, setVisitDays] = useState(null);
    const [servicePlans, setServicePlans] = useState(null);
    const [serviceShifts, setServiceShifts] = useState(null);
    const [visitShiftCheckLists, setVisitShiftCheckLists] = useState(null);

    const initCollapse = (data) => {
        return data.map((item) => ({
            ...item,
            collapse: false
        }));
    }

    // const getClientDataById = async (id) => {
    //     try {
    //         const response = await clientDataById({
    //             variables: {
    //                 id: id
    //             }
    //         })
    //         if (response?.data) {
    //             const copy = { ...response?.data?.clientDetail?.data };
    //             setClientData(copy);
    //         } else {
    //             throw "network issue or not found"
    //         }
    //     } catch (error) {
    //         console.log(error)
    //     }
    // }

    // const getMedicalInfoDataId = async (id) => {
    //     try {
    //         const response = await medicalInfoById({
    //             variables: {
    //                 "filter": {
    //                     "client_detail_id": {
    //                         "id": {
    //                             "eq": id
    //                         }
    //                     }
    //                 }
    //             }
    //         })
    //         if (response?.data) {
    //             // console.log(response?.data?.medicalInformations?.data);
    //             const copy = response?.data?.medicalInformations?.data;
    //             const fCopy = initCollapse(copy);
    //             setMedicalInfoData(fCopy);
    //         } else {
    //             throw "network issue or not found"
    //         }
    //     } catch (error) {
    //         console.log(error)
    //     }
    // }

    // const getAdmissionInfoById = async (id) => {
    //     try {
    //         const response = await admissionInfoById({
    //             variables: {
    //                 "filter": {
    //                     "client_detail_id": {
    //                         "id": {
    //                             "eq": id
    //                         }
    //                     }
    //                 }
    //             }
    //         })
    //         if (response?.data) {
    //             // console.log(response?.data?.admissionInformations?.data);
    //             const copy = response?.data?.admissionInformations?.data;
    //             // const fCopy = initCollapse(copy);
    //             setAdmission(copy);
    //         } else {
    //             throw "network issue or not found"
    //         }
    //     } catch (error) {
    //         console.log(error)
    //     }
    // }

    // const getReferringDoctorInfoById = async (id) => {
    //     try {
    //         const response = await referringDoctorById({
    //             variables: {
    //                 "filter": {
    //                     "client_detail_id": {
    //                         "id": {
    //                             "eq": id
    //                         }
    //                     }
    //                 }
    //             }
    //         })
    //         if (response?.data) {
    //             // console.log(response?.data?.referringDoctorInformations?.data);
    //             const copy = response?.data?.referringDoctorInformations?.data;
    //             const fCopy = initCollapse(copy)
    //             setReferringDoctor(fCopy);
    //         } else {
    //             throw "network issue or not found"
    //         }
    //     } catch (error) {
    //         console.log(error)
    //     }
    // }

    // const getIndividualRiskByid = async (id) => {
    //     try {
    //         const response = await individualRiskByid({
    //             variables: {
    //                 "id": id
    //             }
    //         })
    //         if (response?.data) {
    //             const copy = { ...response?.data?.individualRiskAssessment?.data };
    //             const tempCopy = copy?.attributes?.client_question_answer_refs?.data
    //             const listArray = [];
    //             for (const key in tempCopy) {
    //                 const category = tempCopy[key]?.attributes?.design_front_category_id?.data?.attributes?.title;
    //                 const question = tempCopy[key]?.attributes?.question_list_id?.data?.attributes?.question;
    //                 const answers = tempCopy[key]?.attributes?.answer_type[0];
    //                 const find = listArray?.find((e) => e.category === category);
    //                 if (!find) {
    //                     const obj = {
    //                         category: category,
    //                         questionAnswer: [{
    //                             question: question,
    //                             answers: answers
    //                         }]
    //                     }
    //                     listArray.push(obj)
    //                 }
    //                 if (find) {
    //                     find.questionAnswer.push({
    //                         question: question,
    //                         answers: answers
    //                     })
    //                 }
    //             }
    //             const fCopy = initCollapse(listArray);
    //             setIndividualRisk(fCopy);
    //         } else {
    //             throw "network issue or not found"
    //         }
    //     } catch (error) {
    //         console.log(error)
    //     }
    // }

    // const getHomeRiskAssessmentByid = async (id) => {
    //     try {
    //         const response = await homeRiskAssessmentByid({
    //             variables: {
    //                 "id": id
    //             }
    //         })
    //         if (response?.data) {
    //             const copy = { ...response?.data?.homeRiskAssessment?.data };
    //             const tempCopy = copy?.attributes?.client_question_answer_refs?.data;
    //             const tempInput = copy?.attributes?.client_answer_lovs_references?.data;
    //             const listArray = [];
    //             if (tempInput) {
    //                 for (const key in tempInput) {
    //                     const category = tempInput[key]?.attributes?.design_front_category_id?.data?.attributes?.title;
    //                     const input_name = tempInput[key]?.attributes?.design_frontend_input_id?.data?.attributes?.input_name;
    //                     const answers = tempInput[key]?.attributes?.drop_down_option_lov_id?.data?.attributes?.title;
    //                     const find = listArray?.find((e) => e.category === category);
    //                     if (!find) {
    //                         const obj = {
    //                             category: category,
    //                             questionAnswer: [{
    //                                 question: input_name,
    //                                 answers: answers
    //                             }]
    //                         }
    //                         listArray.push(obj)
    //                     }
    //                     // if (find) {
    //                     //     find.questionAnswer.push({
    //                     //         question: question,
    //                     //         answers: answers
    //                     //     })
    //                     // }
    //                 }
    //             }
    //             for (const key in tempCopy) {
    //                 const category = tempCopy[key]?.attributes?.design_front_category_id?.data?.attributes?.title;
    //                 const question = tempCopy[key]?.attributes?.question_list_id?.data?.attributes?.question;
    //                 const answers = tempCopy[key]?.attributes?.answer_type[0];
    //                 const find = listArray?.find((e) => e.category === category);
    //                 if (!find) {
    //                     const obj = {
    //                         category: category,
    //                         questionAnswer: [{
    //                             question: question,
    //                             answers: answers
    //                         }]
    //                     }
    //                     listArray.push(obj)
    //                 }
    //                 if (find) {
    //                     find.questionAnswer.push({
    //                         question: question,
    //                         answers: answers
    //                     })
    //                 }
    //             }
    //             const fCopy = initCollapse(listArray);
    //             setHomeRiskData(fCopy);
    //         } else {
    //             throw "network issue or not found"
    //         }
    //     } catch (error) {
    //         console.log(error)
    //     }
    // }

    // const getSafetyPlanById = async (id) => {
    //     try {
    //         const response = await safetyPlanById({
    //             variables: {
    //                 "id": id
    //             }
    //         })
    //         if (response?.data) {
    //             const copy = { ...response?.data?.safetyPlan?.data };
    //             const tempCopy = copy?.attributes?.client_question_answer_refs?.data;
    //             const listArray = [];
    //             for (const key in tempCopy) {
    //                 const category = tempCopy[key]?.attributes?.design_front_category_id?.data?.attributes?.title;
    //                 const question = tempCopy[key]?.attributes?.question_list_id?.data?.attributes?.question;
    //                 const answers = tempCopy[key]?.attributes?.answer_type[0]?.order_unord_json;
    //                 const find = listArray?.find((e) => e.category === category);
    //                 if (!find) {
    //                     const obj = {
    //                         category: category,
    //                         questionAnswer: [{
    //                             question: question,
    //                             answers: answers
    //                         }]
    //                     }
    //                     listArray.push(obj)
    //                 }
    //                 if (find) {
    //                     find.questionAnswer.push({
    //                         question: question,
    //                         answers: answers
    //                     })
    //                 }
    //             }
    //             setSafteyPlans(listArray)
    //         } else {
    //             throw "network issue or not found"
    //         }
    //     } catch (error) {
    //         console.log(error)
    //     }
    // }

    // const getClientGoalsAndNotesById = async (id) => {
    //     try {
    //         const response = await clientGoalsAndCareDetailsById({
    //             variables: {
    //                 "filter": {
    //                     "client_detail_id": {
    //                         "id": {
    //                             "eq": id
    //                         }
    //                     }
    //                 }
    //             }
    //         })
    //         if (response?.data) {
    //             const copy = response?.data;
    //             const copies = {
    //                 clientGoals: initCollapse(copy?.clientGoals?.data),
    //                 clientNotes: initCollapse(copy?.clientNotes?.data)
    //             }
    //             const careCopy = {
    //                 serviceInfo: initCollapse(copy?.serviceInformations?.data),
    //                 preferenceWorker: initCollapse(copy?.preferenceForStaffWorkers?.data)
    //             }
    //             const review = {
    //                 checkList: initCollapse(copy?.checkListAlertsNotifications?.data),
    //                 planReview: initCollapse(copy?.planReviews?.data)
    //             }
    //             setGoalsAndNotes(copies);
    //             setCareDetails(careCopy);
    //             setReview(review);
    //         } else {
    //             throw "network issue or not found"
    //         }
    //     } catch (error) {
    //         console.log(error)
    //     }
    // }

    const filterRiskQuestionBymodule = (data) => {
        const individual_risk_assesment = [];
        const home_risk_assesment = [];
        for (const key in data) {
            const object = data[key].attributes;
            if (object.module.includes("Individual")) {
                const find = individual_risk_assesment.find((e) => e.category === object.category);
                if (!find) {
                    const fObject = {
                        category: object.category,
                        collapse: false,
                        questionList: [{
                            answer_type: {
                                management_guides: object.answer_type[0].management_guides,
                                source_of_information: object.answer_type[0].source_of_information,
                                risk_to_individual: object.answer_type[0].risk_options.risk_to_individual.data[0].attributes.title,
                                risk_to_other: object.answer_type[0].risk_options.risk_to_individual.data[0].attributes.title,
                                risk_to_staff: object.answer_type[0].risk_options.risk_to_individual.data[0].attributes.title,
                            },
                            module: object.module,
                            question_title: object.question_title,
                            client_id: object.client_detail_id.data.id,
                        }]
                    }
                    individual_risk_assesment.push(fObject)
                } else {
                    find.questionList.push({
                        answer_type: {
                            management_guides: object.answer_type[0].management_guides,
                            source_of_information: object.answer_type[0].source_of_information,
                            risk_to_individual: object.answer_type[0].risk_options.risk_to_individual.data[0].attributes.title,
                            risk_to_other: object.answer_type[0].risk_options.risk_to_individual.data[0].attributes.title,
                            risk_to_staff: object.answer_type[0].risk_options.risk_to_individual.data[0].attributes.title,
                        },
                        module: object.module,
                        question_title: object.question_title,
                        client_id: object.client_detail_id.data.id,
                    })
                }
            }
            if (object.module.includes("Home")) {
                const find = home_risk_assesment.find((e) => e.category === object.category);
                if (!find) {
                    const fObject = {
                        category: object.category,
                        collapse: false,
                        questionList: [{
                            answer_type: {
                                details_action: object.answer_type[0].details_action,
                                rating: object.answer_type[0].rating,
                                selected_options: object?.answer_type[0]?.selected_options?.data[0]?.attributes?.title,
                            },
                            module: object.module,
                            question_title: object.question_title,
                            client_id: object.client_detail_id.data.id,
                        }]
                    }
                    home_risk_assesment.push(fObject)
                } else {
                    find.questionList.push({
                        answer_type: {
                            details_action: object.answer_type[0].details_action,
                            rating: object.answer_type[0].rating,
                            selected_options: object?.answer_type[0]?.selected_options?.data[0]?.attributes?.title,
                        },
                        module: object.module,
                        question_title: object.question_title,
                        client_id: object.client_detail_id.data.id,
                    })
                }
            }
        }
        return { individual: individual_risk_assesment, home: home_risk_assesment };
    }

    const getAllClientInforamtionsByID = async (id) => {
        try {
            const response = await getClientRelationalInfoById({
                variables: {
                    "id": id,
                    "filterNotes": {
                        "client_detail_id": {
                            "id": {
                                "eq": id
                            }
                        }
                    },
                    "filter": {
                        "client_detail_id": {
                            "id": {
                                "eq": id
                            }
                        }
                    },
                    "filterContact": {
                        "client_detail_id": {
                            "id": {
                                "eq": id
                            }
                        }
                    },
                    "filterEmer": {
                        "client_detail_id": {
                            "id": {
                                "eq": id
                            }
                        }
                    },
                    "medico": {
                        "client_detail_id": {
                            "id": {
                                "eq": id
                            }
                        }
                    },
                    "admission": {
                        "client_detail_id": {
                            "id": {
                                "eq": id
                            }
                        }
                    },
                    "referring": {
                        "client_detail_id": {
                            "id": {
                                "eq": id
                            }
                        }
                    },
                    "filterG": {
                        "client_detail_id": {
                            "id": {
                                "eq": id
                            }
                        }
                    },
                    "filterNote": {
                        "client_detail_id": {
                            "id": {
                                "eq": id
                            }
                        }
                    },
                    "filterWorker": {
                        "client_detail_id": {
                            "id": {
                                "eq": id
                            }
                        }
                    },
                    "filterService": {
                        "client_detail_id": {
                            "id": {
                                "eq": id
                            }
                        }
                    },
                    "filterNotifications": {
                        "client_detail_id": {
                            "id": {
                                "eq": id
                            }
                        }
                    },
                    "filterPlans": {
                        "client_detail_id": {
                            "id": {
                                "eq": id
                            }
                        }
                    },
                    "mediHis": {
                        "client_detail_id": {
                            "id": {
                                "eq": id
                            }
                        }
                    },
                    "question": {
                        "client_detail_id": {
                            "id": {
                                "eq": id
                            }
                        }
                    }
                }
            });
            if (response?.data) {
                console.log(response?.data);
                const copy = response?.data
                if (copy) {
                    setClientData(copy?.clientDetail);
                    setClientDropDownAnswers(copy?.clientAnswerLovsReferences?.data)
                    setEmergencyContactDetails(copy?.emergencyContactDetails?.data[0])
                    const medicalInformations = initCollapse(copy?.medicalInformations?.data);
                    setMedicalInfoData(medicalInformations)
                    const referringDoctorInformations = initCollapse(copy?.referringDoctorInformations?.data);
                    setReferringDoctor(referringDoctorInformations)
                    const admissionInformations = initCollapse(copy?.admissionInformations?.data);
                    setAdmission(admissionInformations)
                    const riskAssessment = filterRiskQuestionBymodule(copy?.clientQuestionAnswerRefs?.data);
                    setIndividualRisk(riskAssessment.individual);
                    setHomeRiskData(riskAssessment.home);
                }
            }
            const response_serv = await getHistoryOfClientService({
                variables: {
                    "Rfilter": {
                        "status": { "eq": "In progress" },
                        "client_detail_id": {
                            "id": {
                                "eq": id
                            }
                        }
                    }
                }
            });
            // "Vfilter": {
            //             "service_request_id": {
            //                 "client_detail_id": {
            //                     "id": {
            //                         "eq": id
            //                     }
            //                 }
            //             }
            //         },
            //         "Cfilter": {
            //             "client_detail_id": {
            //                 "id": {
            //                     "eq": id
            //                 }
            //             }
            //         },
            //         "Pfilter": {
            //             "service_request_id": {
            //                 "client_detail_id": {
            //                     "id": {
            //                         "eq": id
            //                     }
            //                 }
            //             }
            //         },
            //         "Sfilter": {
            //             "visit_day_id": {
            //                 "service_request_id": {
            //                     "client_detail_id": {
            //                         "id": {
            //                             "eq": id
            //                         }
            //                     }
            //                 }
            //             }
            //         }
            if (response_serv?.data) {
                const service_request = response_serv?.data.serviceRequests;
                // if (copy) {
                //     if (copy?.serviceRequests) {
                //         const active = copy?.serviceRequests?.data?.find((e) => e?.attributes?.status === "In progress");
                //         setActiveService(active)
                //     }
                //     setServiceReuest(copy?.serviceRequests)
                //     setVisitDays(copy?.visitDays)
                //     setServicePlans(copy?.servicePlans)
                //     setServiceShifts(copy?.serviceShifts)
                //     setVisitShiftCheckLists(copy?.visitShiftCheckLists)
                // }
            }
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        if (_history) {
            getAllClientInforamtionsByID(_history)
            // getClientDataById(_history);
            // getMedicalInfoDataId(_history);
            // getReferringDoctorInfoById(_history);
            // getAdmissionInfoById(_history);
            // getIndividualRiskByid(_history);
            // getHomeRiskAssessmentByid(_history);
            // getSafetyPlanById(_history);
            // getClientGoalsAndNotesById(_history)
        }
    }, [_history])

    const [activeTab, setActiveTab] = useState("schedual");
    const [serviceTab, setServiceTab] = useState("ongoing");
    const [incidentsTab, setIncidentsTab] = useState("incidents");
    const [riskAssessment, setRiskAssessment] = useState("individual");

    const weekdays = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
    const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"]

    const meetingDate = new Date().getDate() + " " + months[new Date().getMonth()] + " " + new Date().getFullYear() + ", " + weekdays[new Date().getDay()]

    const [selectedDate, setSelectedDate] = useState("2024-07-19");
    const [dayTimesMeet, setDayTimesMeet] = useState(null)

    useEffect(() => {
        const objectOfTimes = generateDayTimes(selectedDate);
        const copy = [...objectOfTimes];
        copy[30].meeting.push({ meeting_time: "3:00 PM" })
        copy[31].meeting.push({ meeting_time: "3:30 PM" })
        copy[32].meeting.push({ meeting_time: "4:00 PM" })
        copy[33].meeting.push({ meeting_time: "4:30 PM" })
        setDayTimesMeet(copy);
    }, [])

    const [hidden, setHidden] = useState(false);

    const handleCollapse = (index, data, setData) => {
        const copy = [...data];
        for (const key in copy) {
            copy[key].collapse = !copy[index].collapse;
        }
        // copy[index].collapse = !copy[index].collapse;
        setData(copy)
    }
    const handleCollapseRisk = (index, data, setData) => {
        const copy = [...data];
        for (const key in copy) {
            copy[key].collapse = false;
        }
        copy[index].collapse = !copy[index].collapse;
        setData(copy)
    }
    // useEffect(() => {
    //     const active = service.find((e) => e.collapse === true);
    //     for (const key in active.visit_day) {
    //         if (active.visit_day[key].collapse) {
    //             setActiveServiceCheck(active.visit_day[key].visit_shift_checklist)
    //         }
    // }
    // setActiveService(active)
    // }, [service])

    const [personal, setPersonal] = useState([
        {
            title: "Demographics",
            country_of_birth: "Pakistan",
            family_status: "Married",
            living_arrangment: "Family",
            religion: "Islam",
            citizenship: "Australia",
            background: "Asian",
            indigenous: "Yes",
            collapse: true,
        },
        {
            title: "Contact Details",
            email: "admin@cennasupport.com",
            home_phone: "6598878899",
            mobile_phone: "6598878899",
            work_phone: "6598878899",
            address1: "australia",
            address2: "australia",
            country: "Perth",
            state: "wa",
            town: "Berri",
            post_code: "58999",
            collapse: false,
        },
        {
            title: "Centerlink / DVA / Healthcare Number",
            medical_number: "6548978",
            medical_expiry_date: "32-05-2021",
            centerlink_dva_number: "65y87",
            collapse: false,
        },
    ])

    // useEffect(() => {
    //     if (!loading && clientData) {
    //         const copy = [...personal];
    //         copy[0].country_of_birth = clientData?.attributes?.client_answer_lovs_references?.data?.find((e) => e.attributes.design_frontend_input_id.data.attributes.input_name.toLowerCase() === "country of birth")?.attributes?.drop_down_option_lov_id?.data?.attributes?.title;
    //         copy[0].family_status = clientData?.attributes?.client_answer_lovs_references?.data?.find((e) => e.attributes.design_frontend_input_id.data.attributes.input_name.toLowerCase() === "family status")?.attributes?.drop_down_option_lov_id?.data?.attributes?.title;
    //         copy[0].living_arrangment = clientData?.attributes?.client_answer_lovs_references?.data?.find((e) => e.attributes.design_frontend_input_id.data.attributes.input_name.toLowerCase() === "living arrangements")?.attributes?.drop_down_option_lov_id?.data?.attributes?.title;
    //         copy[0].religion = clientData?.attributes?.client_answer_lovs_references?.data?.find((e) => e.attributes.design_frontend_input_id.data.attributes.input_name.toLowerCase() === "religion")?.attributes?.drop_down_option_lov_id?.data?.attributes?.title;
    //         copy[0].citizenship = clientData?.attributes?.client_answer_lovs_references?.data?.find((e) => e.attributes.design_frontend_input_id.data.attributes.input_name.toLowerCase() === "citizenship")?.attributes?.drop_down_option_lov_id?.data?.attributes?.title;
    //         copy[0].background = clientData?.attributes?.client_answer_lovs_references?.data?.find((e) => e.attributes.design_frontend_input_id.data.attributes.input_name.toLowerCase() === "background")?.attributes?.drop_down_option_lov_id?.data?.attributes?.title;
    //         copy[0].indigenous = clientData?.attributes?.client_answer_lovs_references?.data?.find((e) => e.attributes.design_frontend_input_id.data.attributes.input_name.toLowerCase() === "indigenous?")?.attributes?.drop_down_option_lov_id?.data?.attributes?.title;

    //         copy[1].email = clientData?.attributes?.contact_details_id?.data?.attributes?.email;
    //         copy[1].home_phone = clientData?.attributes?.contact_details_id?.data?.attributes?.home_phone;
    //         copy[1].mobile_phone = clientData?.attributes?.contact_details_id?.data?.attributes?.mobile_phone;
    //         copy[1].work_phone = clientData?.attributes?.contact_details_id?.data?.attributes?.work_phone;
    //         copy[1].address1 = clientData?.attributes?.contact_details_id?.data?.attributes?.address_1;
    //         copy[1].address2 = clientData?.attributes?.contact_details_id?.data?.attributes?.address_2;
    //         copy[1].country = clientData?.attributes?.contact_details_id?.data?.attributes?.client_answer_lovs_references?.data?.find((e) => e.attributes.design_frontend_input_id.data.attributes.input_name.toLowerCase() === "country")?.attributes?.drop_down_option_lov_id?.data?.attributes?.title;
    //         copy[1].state = clientData?.attributes?.contact_details_id?.data?.attributes?.client_answer_lovs_references?.data?.find((e) => e.attributes.design_frontend_input_id.data.attributes.input_name.toLowerCase() === "state")?.attributes?.drop_down_option_lov_id?.data?.attributes?.title;
    //         copy[1].town = clientData?.attributes?.contact_details_id?.data?.attributes?.client_answer_lovs_references?.data?.find((e) => e.attributes.design_frontend_input_id.data.attributes.input_name.toLowerCase() === "town / suburb")?.attributes?.drop_down_option_lov_id?.data?.attributes?.title;
    //         copy[1].post_code = clientData?.attributes?.contact_details_id?.data?.attributes?.post_code;

    //         copy[2].medical_number = clientData?.attributes?.medicare_number;
    //         copy[2].medical_expiry_date = clientData?.attributes?.medicare_expiry_date;
    //         copy[2].centerlink_dva_number = clientData?.attributes?.centerlink_dva_number;

    //         setPersonal(copy);
    //     }
    // }, [clientData])

    const handleCollapseMedical = (index) => {
        const copy = [...medicalInfoData]
        for (const key in copy) {
            copy[key].collapse = !copy[index].collapse;
        }
        // copy[index].collapse = !copy[index].collapse;
        setMedicalInfoData(copy)
    }

    const handleGoalsAndNotes = (index, dataString) => {
        const copy = { ...goalsAndNotes }
        for (const key in copy.clientGoals) {
            copy.clientGoals[key].collapse = false;
        }
        for (const key in copy.clientNotes) {
            copy.clientNotes[key].collapse = false;
        }
        copy[dataString][index].collapse = !copy[dataString][index].collapse;
        setGoalsAndNotes(copy)
    }

    const handleCareDetails = (index, dataString) => {
        const copy = { ...careDetails }
        for (const key in copy.serviceInfo) {
            copy.serviceInfo[key].collapse = false;
        }
        for (const key in copy.preferenceWorker) {
            copy.preferenceWorker[key].collapse = false;
        }
        copy[dataString][index].collapse = !copy[dataString][index].collapse;
        setCareDetails(copy)
    }

    const handleReview = (index, dataString) => {
        const copy = { ...review }
        for (const key in copy.checkList) {
            copy.checkList[key].collapse = false;
        }
        for (const key in copy.planReview) {
            copy.planReview[key].collapse = false;
        }
        copy[dataString][index].collapse = !copy[dataString][index].collapse;
        setReview(copy)
    }

    const gender = clientDropDownAnswers?.find((e) => e.attributes.input_name.toLowerCase() === "gender")
    const religion = clientDropDownAnswers?.find((e) => e.attributes.input_name.toLowerCase() === "religion")
    const statement_mode = clientDropDownAnswers?.find((e) => e.attributes.input_name.toLowerCase() === "statement preference")
    const service_type = clientDropDownAnswers?.find((e) => e.attributes.input_name.toLowerCase() === "type of services")

    const createColor = (value) => {
        const color = value === "Green" ? "primary" : value === "Yellow" ? "#fcc400" : value
        return color
    }


    useEffect(() => {
        if (activeService) {
            console.log("activeService", activeService);

        }
    }, [activeService])

    return (
        <Grid container sx={{ bgcolor: "#F5F7F6", }}>
            {/* ============= Main Container =============== */}
            {loading_de ? (
                <Box
                    sx={{
                        position: "fixed",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                    }}
                >
                    <CircularProgress color="secondary" size={50} />
                </Box>
            ) : (
                <Grid item={true} xs={12} sm={12} md={12} lg={12} sx={{ display: "flex", flexDirection: "row", height: window.screen.width > 1600 ? "90vh" : "92vh" }}>
                    {/* ================ left container =============== */}
                    <Grid item={true} xs={11} sm={3} md={3} lg={2} >
                        <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center", backgroundColor: "#fff", border: "1px solid #6ECA35", mx: 3, my: 2, borderRadius: 4, height: 255 }}>
                            <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                <Avatar sx={{ width: 70, height: 70, boxShadow: "0px 0px 4px 4px rgba(0, 0, 0, 0.14)", }} alt={clientData?.data?.attributes?.first_name} src={`${IMAGE}${clientData?.data?.attributes?.profile_picture?.data?.attributes?.url}`} />
                                {/* <Avatar sx={{ width: 75, height: 75 }} src={`/assets/images/Leanna-B_thumb.jpg`} /> */}
                            </Box>
                            <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", p: 0.5 }}>
                                <Typography color="primaryText" sx={{ fontFamily: "Roboto-Medium", fontSize: "1em" }}>
                                    {clientData?.data?.attributes?.first_name + " " + clientData?.data?.attributes?.last_name}
                                </Typography>
                            </Box>
                            <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", p: 0.5 }}>
                                <Typography color="secondary.text2" sx={{ fontFamily: "Roboto-Medium", fontSize: "1em" }}>
                                    Client Id: {clientData?.data?.id}
                                </Typography>
                            </Box>
                            <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column" }}>
                                <Switch color="primary" checked={clientData?.data?.attributes?.active} onClick={() => setActive(!clientData?.data?.attributes?.active)} />
                                {clientData?.data?.attributes?.active ?
                                    <Typography color={"primary"} sx={{ fontFamily: "Roboto-Regular", fontSize: "1em" }}>
                                        Active
                                    </Typography> :
                                    <Typography color={"secondary.text3"} sx={{ fontFamily: "Roboto-Regular", fontSize: "1em" }}>
                                        Inactive
                                    </Typography>
                                }
                            </Box>
                        </Box>
                        <Box sx={{ display: "flex", flexDirection: "column", py: 1, px: 2, backgroundColor: "#fff", border: "1px solid #6ECA35", mx: 3, my: 2, borderRadius: 4, height: 255 }}>
                            <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                <Typography color="primaryText" sx={{ fontFamily: "Roboto-Bold", fontSize: "1em" }}>
                                    General Information
                                </Typography>
                                <IconButton>
                                    <BorderColorOutlinedIcon color='primary' />
                                </IconButton>
                            </Box>
                            <Box sx={{ display: "flex", justifyContent: 'center', alignItems: "center", flexDirection: "column" }}>
                                <ListItem
                                    sx={{ p: 0, m: 0 }}
                                    disableGutters
                                    secondaryAction={
                                        <Typography color="primaryText" sx={{ fontFamily: "Roboto-Bold", fontSize: "0.9em" }}>
                                            {moment(clientData?.data?.attributes?.date_of_birth).format("DD/MM/YYYY")}
                                        </Typography>
                                    }
                                >
                                    <ListItemText sx={{ m: 0 }} primaryTypographyProps={{ sx: { color: "secondary.text2", fontSize: "0.9em" } }} primary="Date of Birth:" />
                                </ListItem>
                                <ListItem
                                    sx={{ p: 0, m: 0 }}
                                    disableGutters
                                    secondaryAction={
                                        <Typography color="primaryText" sx={{ fontFamily: "Roboto-Bold", fontSize: "0.9em" }}>
                                            {clientData?.data?.attributes?.age}
                                        </Typography>
                                    }
                                >
                                    <ListItemText sx={{ m: 0 }} primaryTypographyProps={{ sx: { color: "secondary.text2", fontSize: "0.9em" } }} primary="Age:" />
                                </ListItem>
                                <ListItem
                                    sx={{ p: 0, m: 0 }}
                                    disableGutters
                                    secondaryAction={
                                        <Typography color="primaryText" sx={{ fontFamily: "Roboto-Bold", fontSize: "0.9em" }}>
                                            {gender?.attributes?.selected_option_value}
                                        </Typography>
                                    }
                                >
                                    <ListItemText sx={{ m: 0 }} primaryTypographyProps={{ sx: { color: "secondary.text2", fontSize: "0.9em" } }} primary="Gender:" />
                                </ListItem>
                                <ListItem
                                    sx={{ p: 0, m: 0 }}
                                    disableGutters
                                    secondaryAction={
                                        <Typography color="primaryText" sx={{ fontFamily: "Roboto-Bold", fontSize: "0.9em" }}>
                                            {religion?.attributes?.selected_option_value}
                                        </Typography>
                                    }
                                >
                                    <ListItemText sx={{ m: 0 }} primaryTypographyProps={{ sx: { color: "secondary.text2", fontSize: "0.9em" } }} primary="Religion:" />
                                </ListItem>
                                <ListItem
                                    sx={{ p: 0, m: 0 }}
                                    disableGutters
                                    secondaryAction={
                                        <Typography color="primaryText" sx={{ fontFamily: "Roboto-Bold", fontSize: "0.9em" }}>
                                            {statement_mode?.attributes?.selected_option_value}
                                        </Typography>
                                    }
                                >
                                    <ListItemText sx={{ m: 0 }} primaryTypographyProps={{ sx: { color: "secondary.text2", fontSize: "0.9em" } }} primary="Statement Mode:" />
                                </ListItem>
                                <ListItem
                                    sx={{ p: 0, m: 0 }}
                                    disableGutters
                                    secondaryAction={
                                        <Typography color="primaryText" sx={{ fontFamily: "Roboto-Bold", fontSize: "0.9em" }}>
                                            English
                                        </Typography>
                                    }
                                >
                                    <ListItemText sx={{ m: 0 }} primaryTypographyProps={{ sx: { color: "secondary.text2", fontSize: "0.9em" } }} primary="Language Spoken:" />
                                </ListItem>
                                <ListItem
                                    sx={{ p: 0, m: 0 }}
                                    disableGutters
                                    secondaryAction={
                                        <Typography color="primaryText" sx={{ fontFamily: "Roboto-Bold", fontSize: "0.9em" }}>
                                            {clientData?.data?.attributes?.primary_diagnosis}
                                        </Typography>
                                    }
                                >
                                    <Tooltip title="Primary Diagnosis">
                                        <ListItemText sx={{ m: 0 }} primaryTypographyProps={{ sx: { color: "secondary.text2", fontSize: "0.9em" } }} primary="PD:" />
                                    </Tooltip>
                                </ListItem>
                                <ListItem
                                    sx={{ p: 0, m: 0 }}
                                    disableGutters
                                    secondaryAction={
                                        <Typography color="primaryText" sx={{ fontFamily: "Roboto-Bold", fontSize: "0.9em" }}>
                                            {service_type?.attributes?.selected_option_value}
                                        </Typography>
                                    }
                                >
                                    <ListItemText sx={{ m: 0 }} primaryTypographyProps={{ sx: { color: "secondary.text2", fontSize: "0.9em" } }} primary="Service Type:" />
                                </ListItem>
                            </Box>
                        </Box>
                        <Box sx={{ display: "flex", flexDirection: "column", py: 1, px: 2, backgroundColor: "#fff", border: "1px solid #6ECA35", mx: 3, my: 2, borderRadius: 4, height: 255 }}>
                            <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                <Typography color="primaryText" sx={{ fontFamily: "Roboto-Bold", fontSize: "1em" }}>
                                    Emergency Contact
                                </Typography>
                                <IconButton>
                                    <BorderColorOutlinedIcon color='primary' />
                                </IconButton>
                            </Box>
                            <Box sx={{ display: "flex", justifyContent: 'center', alignItems: "center", flexDirection: "column" }}>
                                <ListItem
                                    sx={{ p: 0, m: 0 }}
                                    disableGutters
                                    secondaryAction={
                                        <Typography color="primaryText" sx={{ fontFamily: "Roboto-Bold", fontSize: "0.9em" }}>
                                            {emergencyContactDetails?.attributes?.emergency_first_name + " " + emergencyContactDetails?.attributes?.emergency_last_name}
                                        </Typography>
                                    }
                                >
                                    <ListItemText sx={{ m: 0 }} primaryTypographyProps={{ sx: { color: "secondary.text2", fontSize: "0.9em" } }} primary="Name:" />
                                </ListItem>
                                <ListItem
                                    sx={{ p: 0, m: 0 }}
                                    disableGutters
                                    secondaryAction={
                                        <Typography color="primaryText" sx={{ fontFamily: "Roboto-Bold", fontSize: "0.9em" }}>
                                            {emergencyContactDetails?.attributes?.relationship}
                                        </Typography>
                                    }
                                >
                                    <ListItemText sx={{ m: 0 }} primaryTypographyProps={{ sx: { color: "secondary.text2", fontSize: "0.9em" } }} primary="Relationship:" />
                                </ListItem>
                                <ListItem
                                    sx={{ p: 0, m: 0 }}
                                    disableGutters
                                    secondaryAction={
                                        <Typography color="primaryText" sx={{ fontFamily: "Roboto-Bold", fontSize: "0.9em" }}>
                                            {emergencyContactDetails?.attributes?.home_phone}
                                        </Typography>
                                    }
                                >
                                    <ListItemText sx={{ m: 0 }} primaryTypographyProps={{ sx: { color: "secondary.text2", fontSize: "0.9em" } }} primary="Home Phone:" />
                                </ListItem>
                                <ListItem
                                    sx={{ p: 0, m: 0 }}
                                    disableGutters
                                    secondaryAction={
                                        <Typography color="primaryText" sx={{ fontFamily: "Roboto-Bold", fontSize: "0.9em" }}>
                                            {emergencyContactDetails?.attributes?.mobile_phone}
                                        </Typography>
                                    }
                                >
                                    <ListItemText sx={{ m: 0 }} primaryTypographyProps={{ sx: { color: "secondary.text2", fontSize: "0.9em" } }} primary="Mobile:" />
                                </ListItem>
                                <ListItem
                                    sx={{ p: 0, m: 0 }}
                                    disableGutters
                                    secondaryAction={
                                        <Typography color="primaryText" sx={{ fontFamily: "Roboto-Bold", fontSize: "0.9em" }}>
                                            {emergencyContactDetails?.attributes?.address_1}
                                        </Typography>
                                    }
                                >
                                    <ListItemText sx={{ m: 0 }} primaryTypographyProps={{ sx: { color: "secondary.text2", fontSize: "0.9em" } }} primary="Address 1:" />
                                </ListItem>
                                <ListItem
                                    sx={{ p: 0, m: 0 }}
                                    disableGutters
                                    secondaryAction={
                                        <Typography color="primaryText" sx={{ fontFamily: "Roboto-Bold", fontSize: "0.9em" }}>
                                            {emergencyContactDetails?.attributes?.address_2}
                                        </Typography>
                                    }
                                >
                                    <ListItemText sx={{ m: 0 }} primaryTypographyProps={{ sx: { color: "secondary.text2", fontSize: "0.9em" } }} primary="Address 2:" />
                                </ListItem>
                                <ListItem
                                    sx={{ p: 0, m: 0 }}
                                    disableGutters
                                    secondaryAction={
                                        <Typography color="primaryText" sx={{ fontFamily: "Roboto-Bold", fontSize: "0.9em" }}>
                                            {emergencyContactDetails?.attributes?.email}
                                        </Typography>
                                    }
                                >
                                    <ListItemText sx={{ m: 0 }} primaryTypographyProps={{ sx: { color: "secondary.text2", fontSize: "0.9em" } }} primary="Email:" />
                                </ListItem>
                            </Box>
                        </Box>
                    </Grid>
                    {/* ================ right container =============== */}
                    <Grid item={true} xs={11} sm={9} md={9} lg={10} >
                        <Grid item={true} xs={12} sm={12} md={12} lg={12} sx={{ display: "flex", }}>
                            {activeService ? (
                                <Grid item={true} xs={12} sm={12} md={12} lg={12} sx={{ display: "flex", px: 4, flexDirection: "column", justifyContent: "center", backgroundColor: "#fff", my: 2, height: 90, border: "1px solid #6ECA35", borderRadius: 4, }}>
                                    <Typography color="secondary.text2" sx={{ fontFamily: "Roboto-Medium", fontSize: "1em" }}>
                                        Current Status
                                    </Typography>
                                    <Typography color="secondary.text" sx={{ fontFamily: "Roboto-Medium", fontSize: "1.1em" }}>
                                        {activeService?.attributes?.status} - {activeService?.attributes?.charge_band_rate_id?.data?.attributes?.service_title}
                                        {/* In Progress - Assistance with self care activity */}
                                    </Typography>
                                    <Typography color="primary" sx={{ fontFamily: "Roboto-Medium", fontSize: "0.8em" }}>
                                        Ends in 27 minutes
                                    </Typography>
                                </Grid>
                            ) : (
                                <Grid item={true} xs={12} sm={12} md={12} lg={12} sx={{ display: "flex", px: 4, flexDirection: "column", justifyContent: "center", backgroundColor: "#fff", my: 2, height: 90, border: "1px solid #6ECA35", borderRadius: 4, }}>
                                    <Typography color="secondary.text2" sx={{ fontFamily: "Roboto-Medium", fontSize: "1em" }}>
                                        Nothing Inprogress
                                    </Typography>
                                </Grid>
                            )}

                            <Grid item={true} xs={12} sm={12} md={4} lg={4} sx={{ display: "flex", backgroundColor: "#fff", p: 2, my: 2, mx: 3, height: 90, border: "1px solid #6ECA35", borderRadius: 4, }}>
                                <Box sx={{ width: "90%", }}>
                                    <Typography color="secondary.text3" sx={{ fontFamily: "Roboto-Regular", fontSize: "1em" }}>
                                        NDIS #: <span style={{ color: "#35BE00" }}>{hidden ? clientData?.data?.attributes?.ndis_number : "**********"}</span>
                                    </Typography>
                                    <Typography color="secondary.text3" sx={{ fontFamily: "Roboto-Regular", fontSize: "1em" }}>
                                        Budget Allocated: <span style={{ color: "#35BE00" }}>{hidden ? clientData?.data?.attributes?.client_budget : "$****"}</span>
                                    </Typography>
                                </Box>
                                <Box sx={{ width: "10%", }}>
                                    <IconButton onClick={() => setHidden(!hidden)}>
                                        {hidden ? <VisibilityIcon color="primary" /> : <VisibilityOffOutlinedIcon color="primary" />}
                                    </IconButton>
                                </Box>
                            </Grid>
                        </Grid>
                        <Grid item={true} xs={12} sm={12} md={12} lg={12} sx={{ display: "flex", }}>
                            <Grid item={true} xs={12} sm={12} md={12} lg={12} sx={{ px: 2, display: "flex", justifyContent: "flex start", alignItems: "center", mr: 3, height: 70, border: "2px dotted #999999", }}>
                                <Stack direction={"row"} spacing={2}>
                                    <Button onClick={() => setActiveTab("schedual")} color={"primary"} variant="contained" sx={{ boxShadow: "0px 1px 5px 1px lightgrey", fontSize: "1em", bgcolor: "#ffffff", fontFamily: "Roboto-Medium", textTransform: "capitalize", color: "#000000", bgcolor: activeTab.includes("schedual") ? "primary" : "#ffffff", ":hover": { bgcolor: "primary", } }}>
                                        Service Schedule
                                    </Button>
                                    <Button onClick={() => setActiveTab("medical")} color={"primary"} variant="contained" sx={{ boxShadow: "0px 1px 5px 1px lightgrey", fontSize: "1em", bgcolor: "#ffffff", fontFamily: "Roboto-Medium", textTransform: "capitalize", color: "#000000", bgcolor: activeTab.includes("medical") ? "primary" : "#ffffff", ":hover": { bgcolor: "primary", } }}>
                                        Medical Information
                                    </Button>
                                    <Button onClick={() => setActiveTab("risk")} color={"primary"} variant="contained" sx={{ boxShadow: "0px 1px 5px 1px lightgrey", fontSize: "1em", bgcolor: "#ffffff", fontFamily: "Roboto-Medium", textTransform: "capitalize", color: "#000000", bgcolor: activeTab.includes("risk") ? "primary" : "#ffffff", ":hover": { bgcolor: "primary", } }}>
                                        Risk Assessments
                                    </Button>
                                    <Button onClick={() => setActiveTab("reports")} color={"primary"} variant="contained" sx={{ boxShadow: "0px 1px 5px 1px lightgrey", fontSize: "1em", bgcolor: "#ffffff", fontFamily: "Roboto-Medium", textTransform: "capitalize", color: "#000000", bgcolor: activeTab.includes("reports") ? "primary" : "#ffffff", ":hover": { bgcolor: "primary", } }}>
                                        Incident / Complaint / Feedback
                                    </Button>
                                    <Button onClick={() => setActiveTab("safety")} color={"primary"} variant="contained" sx={{ boxShadow: "0px 1px 5px 1px lightgrey", fontSize: "1em", bgcolor: "#ffffff", fontFamily: "Roboto-Medium", textTransform: "capitalize", color: "#000000", bgcolor: activeTab.includes("safety") ? "primary" : "#ffffff", ":hover": { bgcolor: "primary", } }}>
                                        Safety Plans
                                    </Button>
                                    <Button onClick={() => setActiveTab("details")} color={"primary"} variant="contained" sx={{ boxShadow: "0px 1px 5px 1px lightgrey", fontSize: "1em", bgcolor: "#ffffff", fontFamily: "Roboto-Medium", textTransform: "capitalize", color: "#000000", bgcolor: activeTab.includes("details") ? "primary" : "#ffffff", ":hover": { bgcolor: "primary", } }}>
                                        More Details
                                    </Button>
                                </Stack>
                            </Grid>
                        </Grid>
                        {/* tab container */}
                        <Grid item={true} xs={12} sm={12} md={12} lg={12} sx={{ display: "flex", }}>
                            {/* handling active tabs */}
                            {activeTab.includes("schedual") && (
                                <Grid item={true} xs={12} sm={12} md={12} lg={12} sx={{ my: 2, py: 2, backgroundColor: "#fff", mr: 3, height: "calc(66vh)", border: "1px solid #6ECA35", borderRadius: 4, }}>
                                    <Box sx={{ display: "flex", mx: 4, justifyContent: "flex-end" }}>
                                        <Stack direction={"row"} spacing={2}>
                                            <Button color={"primary"} variant="contained" onClick={() => setServiceTab("ongoing")} sx={{ height: 30, boxShadow: "0px 1px 5px 1px lightgrey", fontSize: "1em", bgcolor: "#ffffff", fontFamily: "Roboto-Bold", textTransform: "capitalize", color: "#000000", bgcolor: serviceTab.includes("ongoing") ? "#6ECA35" : "#ffffff", ":hover": { bgcolor: "#6ECA35", } }}>
                                                Ongoing
                                            </Button>
                                            <Button color={"primary"} variant="contained" onClick={() => setServiceTab("history")} sx={{ height: 30, boxShadow: "0px 1px 5px 1px lightgrey", fontSize: "1em", bgcolor: "#ffffff", fontFamily: "Roboto-Bold", textTransform: "capitalize", color: "#000000", bgcolor: serviceTab.includes("history") ? "#6ECA35" : "#ffffff", ":hover": { bgcolor: "#6ECA35", } }}>
                                                History
                                            </Button>
                                        </Stack>
                                    </Box>
                                    {/* box box bgcolor: "#F7F7F7"*/}
                                    {serviceTab.includes("ongoing") && (
                                        <Box sx={{ display: "flex", flexDirection: "row", my: 2, justifyContent: "space-evenly" }}>
                                            <Box sx={{ width: "47%", }}>
                                                <Box sx={{ overflowY: "auto", my: 1, width: "100%", borderRadius: 5, height: 250, bgcolor: "#F7F7F7" }}>
                                                    <Box sx={{ px: 4, pt: 2 }}>
                                                        <Typography color="primary" sx={{ fontFamily: "Roboto-Bold", fontSize: "1.2em" }}>
                                                            Service Request Information
                                                        </Typography>
                                                    </Box>
                                                    <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", height: "75%" }}>
                                                        <Typography color="secondary.text2" sx={{ fontFamily: "Roboto-Regular", fontSize: "1em" }}>
                                                            No Service
                                                        </Typography>
                                                    </Box>
                                                    {/*  <Box sx={{ px: 4, mb: 2 }}>
                                                    {activeService?.map((v, i) => {
                                                        return (
                                                            <List key={v.title} component={Paper} sx={{ p: 0, my: 1 }}>
                                                                <ListItem>
                                                                    <ListItemText primaryTypographyProps={{
                                                                        fontFamily: "Roboto-Bold",
                                                                        fontSize: "1.2em"
                                                                    }} primary={v.title} secondary={v.service_type} />
                                                                    <Stack direction={"row"} spacing={2}>
                                                                        <IconButton>
                                                                            <BorderColorOutlinedIcon color={"primary"} />
                                                                        </IconButton>
                                                                        {v.collapse ?
                                                                            <Button sx={{ borderRadius: 3, width: 40, minWidth: 40, height: 35 }} onClick={() => handleCollapse(i, service, setService)} variant='contained' color='primary'>
                                                                                <ExpandLess sx={{ color: "#fff", fontSize: 25 }} />
                                                                            </Button>
                                                                            : <Button sx={{ borderRadius: 3, width: 40, minWidth: 40, height: 35 }} onClick={() => handleCollapse(i, service, setService)} variant='contained' color='primary'>
                                                                                <ExpandMore sx={{ color: "#fff", fontSize: 25 }} />
                                                                            </Button>
                                                                        }
                                                                    </Stack>
                                                                </ListItem>
                                                                <Collapse in={v.collapse} timeout="auto" unmountOnExit>
                                                                    <Box sx={{ display: "flex", justifyContent: "center", alignItems: 'center', mb: 1 }}>
                                                                        <Box sx={{ width: "45%", }}>
                                                                            <Typography color="secondary.text3" sx={{ fontFamily: "Roboto-Regular", fontSize: "1.2em" }}>
                                                                                Status: <span style={{ color: "#3A89CC" }}>{v.status}</span>
                                                                            </Typography>
                                                                        </Box>
                                                                        <Box sx={{ width: "45%", }}>
                                                                            <Typography color="secondary.text3" sx={{ fontFamily: "Roboto-Regular", fontSize: "1.2em" }}>
                                                                                Pay Travel: <span style={{ color: "#3A89CC" }}>{v.pay_travel}</span>
                                                                            </Typography>
                                                                        </Box>
                                                                    </Box>
                                                                    <Box sx={{ display: "flex", justifyContent: "center", alignItems: 'center', mb: 1 }}>
                                                                        <Box sx={{ width: "45%", }}>
                                                                            <Typography color="secondary.text3" sx={{ fontFamily: "Roboto-Regular", fontSize: "1.2em" }}>
                                                                                Funding Source: <span style={{ color: "#3A89CC" }}>{v.funding_source}</span>
                                                                            </Typography>
                                                                        </Box>
                                                                        <Box sx={{ width: "45%", }}>
                                                                            <Typography color="secondary.text3" sx={{ fontFamily: "Roboto-Regular", fontSize: "1.2em" }}>
                                                                                Billable: <span style={{ color: "#3A89CC" }}>{v.billable}</span>
                                                                            </Typography>
                                                                        </Box>
                                                                    </Box>
                                                                </Collapse>
                                                            </List>
                                                        )
                                                    })} 
                                                </Box>*/}
                                                </Box>
                                                <Box sx={{ overflowY: "auto", my: 1, width: "100%", borderRadius: 5, height: 250, bgcolor: "#F7F7F7" }}>
                                                    <Box sx={{ px: 4, pt: 2 }}>
                                                        <Typography color="primary" sx={{ fontFamily: "Roboto-Bold", fontSize: "1.2em" }}>
                                                            Visit Days
                                                        </Typography>
                                                    </Box>
                                                    <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", height: "75%" }}>
                                                        <Typography color="secondary.text2" sx={{ fontFamily: "Roboto-Regular", fontSize: "1em" }}>
                                                            No Service
                                                        </Typography>
                                                    </Box>
                                                    {/* <Box sx={{ px: 4, mb: 2 }}>
                                                    {activeService?.visit_day?.map((v, i) => {
                                                        return (
                                                            <List key={v.date} component={Paper} sx={{ p: 0, my: 1 }}>
                                                                <ListItem>
                                                                    <ListItemText primaryTypographyProps={{
                                                                        fontFamily: "Roboto-Bold",
                                                                        fontSize: "1.2em"
                                                                    }} primary={v.date} secondary={v.day} />
                                                                    <Stack direction={"row"} spacing={2}>
                                                                        <IconButton>
                                                                            <BorderColorOutlinedIcon color={"primary"} />
                                                                        </IconButton>
                                                                        {v.collapse ?
                                                                            <Button sx={{ borderRadius: 3, width: 40, minWidth: 40, height: 35 }} onClick={() => handleActiveService(i, activeService, setActiveService)} variant='contained' color='primary'>
                                                                                <ExpandLess sx={{ color: "#fff", fontSize: 25 }} />
                                                                            </Button>
                                                                            : <Button sx={{ borderRadius: 3, width: 40, minWidth: 40, height: 35 }} onClick={() => handleActiveService(i, activeService, setActiveService)} variant='contained' color='primary'>
                                                                                <ExpandMore sx={{ color: "#fff", fontSize: 25 }} />
                                                                            </Button>
                                                                        }
                                                                    </Stack>
                                                                </ListItem>
                                                                <Collapse in={v.collapse} timeout="auto" unmountOnExit>
                                                                    <Box sx={{ display: "flex", flexDirection: 'row', justifyContent: "center", mb: 2 }}>
                                                                        <Box sx={{ width: "45%", }}>
                                                                            <Box sx={{ width: "100%" }}>
                                                                                <Typography color="secondary.text3" sx={{ fontFamily: "Roboto-Regular", fontSize: "1em" }}>
                                                                                    Start Time: {v.start_time}
                                                                                </Typography>
                                                                                <Typography color="secondary.text3" sx={{ fontFamily: "Roboto-Regular", fontSize: "1em" }}>
                                                                                    End Time: {v.end_time}
                                                                                </Typography>
                                                                            </Box>
                                                                        </Box>
                                                                        <Box sx={{ width: "45%", display: "flex", justifyContent: "center", alignItems: "flex-end" }}>
                                                                            <Stack direction={"row"} spacing={2}>
                                                                                <IconButton>
                                                                                    <AccountBalanceWalletOutlinedIcon color={"primary"} />
                                                                                </IconButton>
                                                                                <IconButton>
                                                                                    <DriveEtaOutlinedIcon color={"primary"} />
                                                                                </IconButton>
                                                                                <IconButton>
                                                                                    <AccessTimeOutlinedIcon color={"primary"} />
                                                                                </IconButton>
                                                                                <IconButton>
                                                                                    <WarningAmberOutlinedIcon color={"primary"} />
                                                                                </IconButton>
                                                                            </Stack>
                                                                        </Box>
                                                                    </Box>
                                                                </Collapse>
                                                            </List>
                                                        )
                                                    })}
                                                </Box> */}
                                                </Box>
                                            </Box>
                                            <Box sx={{ width: "47%", }}>
                                                <Box sx={{ my: 1, width: "100%", borderRadius: 5, height: "97%", bgcolor: "#F7F7F7" }}>
                                                    <Box sx={{ px: 4, pt: 2 }}>
                                                        <Typography color="primary" sx={{ fontFamily: "Roboto-Bold", fontSize: "1.2em" }}>
                                                            Visit Shift Checklist
                                                        </Typography>
                                                    </Box>
                                                    <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", height: "75%" }}>
                                                        <Typography color="secondary.text2" sx={{ fontFamily: "Roboto-Regular", fontSize: "1em" }}>
                                                            No Service
                                                        </Typography>
                                                    </Box>
                                                    {/* <Box sx={{ px: 4, mb: 2 }}>
                                                    {activeServiceCheck?.map((v, i) => {
                                                        return (
                                                            <List key={v.title} component={Paper} sx={{ p: 0, my: 1 }}>
                                                                <ListItem>
                                                                    <ListItemIcon>
                                                                        <Checkbox
                                                                            icon={<RadioButtonUncheckedIcon color='primary' sx={{ fontSize: 32 }} />}
                                                                            checkedIcon={<CheckCircleIcon color='primary' sx={{ fontSize: 32 }} />}
                                                                        />
                                                                    </ListItemIcon>
                                                                    <ListItemText primaryTypographyProps={{
                                                                        fontFamily: "Roboto-Bold",
                                                                        fontSize: "1.2em"
                                                                    }} primary={v.title} />
                                                                    {v.collapse ?
                                                                        <Button sx={{ borderRadius: 3, width: 40, minWidth: 40, height: 35 }} onClick={() => handleCollapse(i, activeServiceCheck, setActiveServiceCheck)} variant='contained' color='primary'>
                                                                            <ExpandLess sx={{ color: "#fff", fontSize: 25 }} />
                                                                        </Button> :
                                                                        <Button sx={{ borderRadius: 3, width: 40, minWidth: 40, height: 35 }} onClick={() => handleCollapse(i, activeServiceCheck, setActiveServiceCheck)} variant='contained' color='primary'>
                                                                            <ExpandMore sx={{ color: "#fff", fontSize: 25 }} />
                                                                        </Button>
                                                                    }
                                                                </ListItem>
                                                                <Collapse in={v.collapse} timeout="auto" unmountOnExit>
                                                                    <Box sx={{ display: "flex", justifyContent: "center", alignItems: 'center', mb: 1 }}>
                                                                        <Box sx={{ width: "80%", display: "flex", justifyContent: "center" }}>
                                                                            <TextField
                                                                                placeholder='Add a comment'
                                                                                value={v.comment}
                                                                                sx={{ width: "inherit" }}
                                                                                multiline={true}
                                                                            />
                                                                        </Box>
                                                                    </Box>
                                                                </Collapse>
                                                            </List>
                                                        )
                                                    })}
                                                </Box> */}
                                                </Box>
                                            </Box>
                                        </Box>
                                    )}
                                    {serviceTab.includes("history") && (
                                        <Box sx={{ display: "flex", justifyContent: "center", my: 2, }}>
                                            <Box sx={{ width: "96%", height: 510, overflowY: "auto", my: 1, borderRadius: 5, bgcolor: "#F7F7F7" }}>
                                                <Box sx={{ px: 4, pt: 2 }}>
                                                    <Typography color="primary" sx={{ fontFamily: "Roboto-Bold", fontSize: "1.2em" }}>
                                                        Service Request History
                                                    </Typography>
                                                </Box>
                                                <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", height: "75%" }}>
                                                    <Typography color="secondary.text2" sx={{ fontFamily: "Roboto-Regular", fontSize: "1em" }}>
                                                        No Service
                                                    </Typography>
                                                </Box>
                                                {/* <Box sx={{ px: 4, mb: 2 }}>
                                                {serviceHistory?.map((v, i) => {
                                                    return (
                                                        <List key={v.title} component={Paper} sx={{ p: 0, my: 1 }}>
                                                            <ListItem>
                                                                <ListItemText primaryTypographyProps={{
                                                                    fontFamily: "Roboto-Bold",
                                                                    fontSize: "1.2em"
                                                                }} primary={v.title} secondary={
                                                                    <Box>
                                                                        <Typography color="secondary.text" sx={{ fontFamily: "Roboto-Medium", fontSize: "1em" }}>
                                                                            {v.visit_day[0]?.date} - {v.visit_day[v.visit_day.length - 1]?.date}
                                                                        </Typography>
                                                                        {v.collapse &&
                                                                            <Typography color="secondary.text" sx={{ fontFamily: "Roboto-Medium", fontSize: "1em" }}>
                                                                                Occurred {v.service_type}
                                                                            </Typography>
                                                                        }
                                                                    </Box>
                                                                } />
                                                                {v.collapse &&
                                                                    <Box sx={{ width: "40%", p: 2 }}>
                                                                        <Box sx={{ display: "flex", justifyContent: "center", alignItems: 'center', }}>
                                                                            <Box sx={{ width: "45%", }}>
                                                                                <Typography color="secondary.text3" sx={{ fontFamily: "Roboto-Regular", fontSize: "1em" }}>
                                                                                    Status: <span style={{ color: "#3A89CC" }}>{v.status}</span>
                                                                                </Typography>
                                                                            </Box>
                                                                            <Box sx={{ width: "45%", }}>
                                                                                <Typography color="secondary.text3" sx={{ fontFamily: "Roboto-Regular", fontSize: "1em" }}>
                                                                                    Pay Travel: <span style={{ color: "#3A89CC" }}>{v.pay_travel}</span>
                                                                                </Typography>
                                                                            </Box>
                                                                        </Box>
                                                                        <Box sx={{ display: "flex", justifyContent: "center", alignItems: 'center', }}>
                                                                            <Box sx={{ width: "45%", }}>
                                                                                <Typography color="secondary.text3" sx={{ fontFamily: "Roboto-Regular", fontSize: "1em" }}>
                                                                                    Funding Source: <span style={{ color: "#3A89CC" }}>{v.funding_source}</span>
                                                                                </Typography>
                                                                            </Box>
                                                                            <Box sx={{ width: "45%", }}>
                                                                                <Typography color="secondary.text3" sx={{ fontFamily: "Roboto-Regular", fontSize: "1em" }}>
                                                                                    Billable: <span style={{ color: "#3A89CC" }}>{v.billable}</span>
                                                                                </Typography>
                                                                            </Box>
                                                                        </Box>
                                                                    </Box>
                                                                }
                                                                {v.collapse ?
                                                                    <Button sx={{ borderRadius: 3, width: 40, minWidth: 40, height: 35 }} onClick={() => handleCollapse(i, serviceHistory, setServiceHistory)} variant='contained' color='primary'>
                                                                        <ExpandLess sx={{ color: "#fff", fontSize: 25 }} />
                                                                    </Button>
                                                                    : <Button sx={{ borderRadius: 3, width: 40, minWidth: 40, height: 35 }} onClick={() => handleCollapse(i, serviceHistory, setServiceHistory)} variant='contained' color='primary'>
                                                                        <ExpandMore sx={{ color: "#fff", fontSize: 25 }} />
                                                                    </Button>
                                                                }
                                                            </ListItem>
                                                            <Collapse in={v.collapse} timeout="auto" unmountOnExit>
                                                                <Box sx={{ px: 2, width: "100%", display: "flex", justifyContent: "center", alignItems: 'center', mb: 2 }}>
                                                                    <Box sx={{ height: 300, width: "60%", display: "flex", flexDirection: "column", }}>
                                                                        <Box sx={{ my: 1, width: "100%", }}>
                                                                            <Typography color="primary.text" sx={{ fontFamily: "Roboto-Regular", fontSize: "1em" }}>
                                                                                Visit Days
                                                                            </Typography>
                                                                        </Box>
                                                                        <Box sx={{ width: "95%", px: 6 }}>
                                                                            {v.visit_day.map((vN, iN) => {
                                                                                return (
                                                                                    <Typography key={iN} color="secondary.text2" sx={{ fontFamily: "Roboto-Regular", fontSize: "1em", my: 1 }}>
                                                                                        <FiberManualRecordIcon color='#000000' sx={{ fontSize: 12, }} />
                                                                                        <span style={{ paddingLeft: "1%" }}>{vN.date} | {vN.start_time} - {vN.end_time} </span>
                                                                                    </Typography>
                                                                                )
                                                                            })}
                                                                        </Box>
                                                                    </Box>
                                                                    <Box sx={{ height: 300, width: "40%", borderRadius: 5, bgcolor: "#F7F7F7", overflowY: "auto" }}>
                                                                        <Box sx={{ px: 3, my: 2, width: "100%", }}>
                                                                            <Typography color="primary.text" sx={{ fontFamily: "Roboto-Regular", fontSize: "1em" }}>
                                                                                Service Request Check List
                                                                            </Typography>
                                                                        </Box>
                                                                        {v.visit_day.map((vN, iN) => {
                                                                            return (
                                                                                <Box key={iN} sx={{ px: 4, }}>
                                                                                    {vN.visit_shift_checklist.map((vY, iY) => {
                                                                                        return (
                                                                                            <ListItem key={iY} sx={{ p: 0 }}>
                                                                                                <ListItemIcon>
                                                                                                    <Checkbox
                                                                                                        checked={vY.completed}
                                                                                                        icon={<RadioButtonUncheckedIcon color='primary' sx={{ fontSize: 32 }} />}
                                                                                                        checkedIcon={<CheckCircleIcon color='primary' sx={{ fontSize: 32 }} />}
                                                                                                    />
                                                                                                </ListItemIcon>
                                                                                                <ListItemText primaryTypographyProps={{
                                                                                                    fontFamily: "Roboto-Bold",
                                                                                                    fontSize: "1.2em"
                                                                                                }} primary={vY.title} secondary={vY.comment} />
                                                                                            </ListItem>
                                                                                        )
                                                                                    })}
                                                                                </Box>
                                                                            )
                                                                        })}
                                                                    </Box>
                                                                </Box>
                                                            </Collapse>
                                                        </List>
                                                    )
                                                })}
                                            </Box> */}
                                            </Box>
                                        </Box>
                                    )}
                                </Grid>
                            )}
                            {activeTab.includes("medical") && (
                                <Grid item={true} xs={12} sm={12} md={12} lg={12} sx={{ display: "flex", flexDirection: "row", my: 2, py: 2, backgroundColor: "#fff", mr: 3, height: "calc(66vh)", border: "1px solid #6ECA35", borderRadius: 4, }}>
                                    {/* left container */}
                                    <Box sx={{ width: "60%", borderRadius: 5, ml: 3, bgcolor: "#F7F7F7", height: "100%", overflowY: "auto" }}>
                                        <Box sx={{ px: 4, pt: 2 }}>
                                            <Typography color="primary" sx={{ fontFamily: "Roboto-Bold", fontSize: "1.2em" }}>
                                                Medication
                                            </Typography>
                                        </Box>
                                        <Box sx={{ px: 4, mb: 2 }}>
                                            {medicalInfoData?.map((v, i) => {
                                                return (
                                                    <List key={v?.attributes?.medication_title} component={Paper} sx={{ p: 0, my: 1 }}>
                                                        <ListItem>
                                                            <ListItemText primaryTypographyProps={{
                                                                fontFamily: "Roboto-Bold",
                                                                fontSize: "1.2em"
                                                            }} primary={v?.attributes?.medication_title} secondary={v?.attributes?.expiry_date} />
                                                            <Stack direction={"row"} spacing={2}>
                                                                <IconButton>
                                                                    <BorderColorOutlinedIcon color={"primary"} />
                                                                </IconButton>
                                                                {v?.collapse ?
                                                                    <Button sx={{ borderRadius: 3, width: 40, minWidth: 40, height: 35 }} onClick={() => handleCollapseMedical(i)} variant='contained' color='primary'>
                                                                        <ExpandLess sx={{ color: "#fff", fontSize: 25 }} />
                                                                    </Button>
                                                                    : <Button sx={{ borderRadius: 3, width: 40, minWidth: 40, height: 35 }} onClick={() => handleCollapseMedical(i)} variant='contained' color='primary'>
                                                                        <ExpandMore sx={{ color: "#fff", fontSize: 25 }} />
                                                                    </Button>
                                                                }
                                                            </Stack>
                                                        </ListItem>
                                                        <Collapse in={v?.collapse} timeout="auto" unmountOnExit>
                                                            <Box sx={{ display: "flex", mb: 1, justifyContent: "center", }}>
                                                                <Box sx={{ display: "flex", flexWrap: "wrap", width: "80%", }}>
                                                                    {v?.attributes?.client_answer_lovs_refs?.data.map((vN, iN) => {
                                                                        return (
                                                                            <Box key={iN} sx={{ width: "50%", }}>
                                                                                <Typography color="secondary.text3" sx={{ fontFamily: "Roboto-Regular", fontSize: "1em" }}>
                                                                                    {vN.attributes.design_frontend_input_id.data.attributes.input_name}: <span style={{ color: "#3A89CC" }}>{vN?.attributes?.drop_down_option_lov_id.data.attributes.title}</span>
                                                                                </Typography>
                                                                            </Box>
                                                                        )
                                                                    })}
                                                                    <Box sx={{ width: "50%", }}>
                                                                        <Typography color="secondary.text3" sx={{ fontFamily: "Roboto-Regular", fontSize: "1em" }}>
                                                                            Dosage: <span style={{ color: "#3A89CC" }}>{v?.attributes?.dosage}</span>
                                                                        </Typography>
                                                                    </Box>
                                                                    <Box sx={{ width: "50%", }}>
                                                                        <Typography color="secondary.text3" sx={{ fontFamily: "Roboto-Regular", fontSize: "1em" }}>
                                                                            Notification Time: <span style={{ color: "#3A89CC" }}>{v?.attributes?.notification_time}</span>
                                                                        </Typography>
                                                                    </Box>
                                                                    <Box sx={{ width: "50%", }}>
                                                                        <Typography color="secondary.text3" sx={{ fontFamily: "Roboto-Regular", fontSize: "1em" }}>
                                                                            Comments: <span style={{ color: "#3A89CC" }}>{v?.attributes?.comments}</span>
                                                                        </Typography>
                                                                    </Box>
                                                                </Box>
                                                            </Box>
                                                        </Collapse>
                                                    </List>
                                                )
                                            })}
                                        </Box>
                                    </Box>
                                    {/* right container */}
                                    <Box sx={{ width: "40%", borderRadius: 5, ml: 3, mr: 3, height: "100%" }}>
                                        <Box sx={{ width: "100%", borderRadius: 5, mb: 1, bgcolor: "#F7F7F7", height: "40%", overflowY: "auto" }}>
                                            <Box sx={{ px: 4, pt: 2 }}>
                                                <Typography color="primary" sx={{ fontFamily: "Roboto-Bold", fontSize: "1.2em" }}>
                                                    Referring Doctor
                                                </Typography>
                                            </Box>
                                            <Box sx={{ px: 4, mb: 2 }}>
                                                {referringDoctorData?.map((v, i) => {
                                                    return (
                                                        <List key={v?.attributes?.referring_doctor} component={Paper} sx={{ p: 0, my: 1 }}>
                                                            <ListItem>
                                                                <ListItemText primaryTypographyProps={{
                                                                    fontFamily: "Roboto-Bold",
                                                                    fontSize: "1.2em"
                                                                }} primary={v?.attributes?.referring_doctor} />
                                                                <Stack direction={"row"} spacing={2}>
                                                                    <IconButton>
                                                                        <BorderColorOutlinedIcon color={"primary"} />
                                                                    </IconButton>
                                                                    {v.collapse ?
                                                                        <Button sx={{ borderRadius: 3, width: 40, minWidth: 40, height: 35 }} onClick={() => handleCollapse(i, referringDoctorData, setReferringDoctor)} variant='contained' color='primary'>
                                                                            <ExpandLess sx={{ color: "#fff", fontSize: 25 }} />
                                                                        </Button>
                                                                        : <Button sx={{ borderRadius: 3, width: 40, minWidth: 40, height: 35 }} onClick={() => handleCollapse(i, referringDoctorData, setReferringDoctor)} variant='contained' color='primary'>
                                                                            <ExpandMore sx={{ color: "#fff", fontSize: 25 }} />
                                                                        </Button>
                                                                    }
                                                                </Stack>
                                                            </ListItem>
                                                            <Collapse in={v.collapse} timeout="auto" unmountOnExit>
                                                                <Box sx={{ display: "flex", justifyContent: "center", alignItems: 'center', mb: 3 }}>
                                                                    <Box sx={{ width: "100%", px: 4 }}>
                                                                        <ListItem
                                                                            sx={{ p: 0, m: 0 }}
                                                                            disableGutters
                                                                            secondaryAction={
                                                                                <Typography color="primary.text" sx={{ fontFamily: "Roboto-Medium", fontSize: "1em" }}>
                                                                                    {v?.attributes?.medical_record_number}
                                                                                </Typography>
                                                                            }
                                                                        >
                                                                            <ListItemText sx={{ m: 0, }} primaryTypographyProps={{ sx: { fontFamily: "Roboto-Medium", color: "secondary.text2", fontSize: "1em" } }} primary="Medical Record:" />
                                                                        </ListItem>
                                                                        <ListItem
                                                                            sx={{ p: 0, m: 0 }}
                                                                            disableGutters
                                                                            secondaryAction={
                                                                                <Typography color="primary.text" sx={{ fontFamily: "Roboto-Medium", fontSize: "1em" }}>
                                                                                    {v?.attributes?.referring_doctor_address}
                                                                                </Typography>
                                                                            }
                                                                        >
                                                                            <ListItemText sx={{ m: 0, }} primaryTypographyProps={{ sx: { fontFamily: "Roboto-Medium", color: "secondary.text2", fontSize: "1em" } }} primary="Address:" />
                                                                        </ListItem>
                                                                        <ListItem
                                                                            sx={{ p: 0, m: 0 }}
                                                                            disableGutters
                                                                            secondaryAction={
                                                                                <Typography color="primary.text" sx={{ fontFamily: "Roboto-Medium", fontSize: "1em" }}>
                                                                                    {v?.attributes?.referring_doctor_phone}
                                                                                </Typography>
                                                                            }
                                                                        >
                                                                            <ListItemText sx={{ m: 0, }} primaryTypographyProps={{ sx: { fontFamily: "Roboto-Medium", color: "secondary.text2", fontSize: "1em" } }} primary="Phone:" />
                                                                        </ListItem>
                                                                    </Box>
                                                                </Box>
                                                            </Collapse>
                                                        </List>
                                                    )
                                                })}
                                            </Box>
                                        </Box>
                                        <Box sx={{ width: "100%", borderRadius: 5, mb: 1, bgcolor: "#F7F7F7", height: "60%" }}>
                                            {/* Head */}
                                            <Box sx={{ px: 4, pt: 1 }}>
                                                <Typography color="primary" sx={{ fontFamily: "Roboto-Bold", fontSize: "1.2em" }}>
                                                    Admission Information
                                                </Typography>
                                            </Box>
                                            {/* card */}
                                            <Box sx={{ px: 4, mb: 1 }}>
                                                <Box sx={{ width: "100%", }}>
                                                    {admissionData?.map((v, i) => {
                                                        return (
                                                            <List component={Paper} sx={{ px: 4, py: 2, my: 1 }}>
                                                                <ListItem
                                                                    sx={{ p: 0, m: 0 }}
                                                                    disableGutters
                                                                    secondaryAction={
                                                                        <Typography color="primary.text" sx={{ fontFamily: "Roboto-Medium", fontSize: "1em" }}>
                                                                            {v?.attributes?.principal_diagnosis}
                                                                        </Typography>
                                                                    }
                                                                >
                                                                    <ListItemText sx={{ m: 0, }} primaryTypographyProps={{ sx: { fontFamily: "Roboto-Medium", color: "secondary.text2", fontSize: "1em" } }} primary="Principal Diagnosis:" />
                                                                </ListItem>
                                                                <ListItem
                                                                    sx={{ p: 0, m: 0 }}
                                                                    disableGutters
                                                                    secondaryAction={
                                                                        <Typography color="primary.text" sx={{ fontFamily: "Roboto-Medium", fontSize: "1em" }}>
                                                                            {v?.attributes?.provisional_diagnosis}
                                                                        </Typography>
                                                                    }
                                                                >
                                                                    <ListItemText sx={{ m: 0, }} primaryTypographyProps={{ sx: { fontFamily: "Roboto-Medium", color: "secondary.text2", fontSize: "1em" } }} primary="Provisional Diagnosis:" />
                                                                </ListItem>
                                                                <ListItem
                                                                    sx={{ p: 0, m: 0 }}
                                                                    disableGutters
                                                                    secondaryAction={
                                                                        <Typography color="primary.text" sx={{ fontFamily: "Roboto-Medium", fontSize: "1em" }}>
                                                                            {v?.attributes?.identified_major_procedure}
                                                                        </Typography>
                                                                    }
                                                                >
                                                                    <ListItemText sx={{ m: 0, }} primaryTypographyProps={{ sx: { fontFamily: "Roboto-Medium", color: "secondary.text2", fontSize: "1em" } }} primary="Identified Major Procedure:" />
                                                                </ListItem>
                                                            </List>
                                                        )
                                                    })}
                                                </Box>
                                            </Box>
                                            <Box sx={{ px: 4, }}>
                                                <Typography color="primary" sx={{ fontFamily: "Roboto-Bold", fontSize: "1.2em" }}>
                                                    Admission Summary
                                                </Typography>
                                            </Box>
                                            {/* card */}
                                            <Box sx={{ px: 4, mb: 1 }}>
                                                <Box sx={{ width: "100%", }}>
                                                    {admissionData?.map((v, i) => {
                                                        // const summary = v?.attributes?.client_question_answer_refs?.data;
                                                        // return (
                                                        //     <List key={summary?.attributes?.__typename + summary?.attributes?.id} component={Paper} sx={{ px: 4, py: 2, my: 1 }}>
                                                        //         {summary?.map((vN, iN) => {
                                                        //             return (
                                                        //                 <ListItem
                                                        //                     key={vN?.attributes?.question_list_id + iN}
                                                        //                     sx={{ p: 0, m: 0 }}
                                                        //                     disableGutters
                                                        //                     secondaryAction={
                                                        //                         <Typography color="primary.text" sx={{ fontFamily: "Roboto-Medium", fontSize: "1em" }}>
                                                        //                             {vN?.attributes?.answer_type[0]?.drop_down_option_lovs.data[0]?.attributes?.title}
                                                        //                         </Typography>
                                                        //                     }
                                                        //                 >
                                                        //                     <ListItemText sx={{ m: 0, }} primaryTypographyProps={{ sx: { fontFamily: "Roboto-Medium", color: "secondary.text2", fontSize: "1em" } }} primary={vN?.attributes?.question_list_id?.data?.attributes?.question} />
                                                        //                 </ListItem>
                                                        //             )
                                                        //         })}
                                                        //     </List>
                                                        // )
                                                    })}
                                                </Box>
                                            </Box>
                                        </Box>
                                    </Box>
                                </Grid>
                            )}
                            {activeTab.includes("risk") && (
                                <Grid item={true} xs={12} sm={12} md={12} lg={12} sx={{ my: 2, py: 2, backgroundColor: "#fff", mr: 3, height: "calc(66vh)", border: "1px solid #6ECA35", borderRadius: 4, }}>
                                    <Box sx={{ display: "flex", mx: 4, justifyContent: "flex-end", mb: 2 }}>
                                        <Stack direction={"row"} spacing={2}>
                                            <Button color={"primary"} variant="contained" onClick={() => setRiskAssessment("individual")} sx={{ height: 30, boxShadow: "0px 1px 5px 1px lightgrey", fontSize: "1em", bgcolor: "#ffffff", fontFamily: "Roboto-Bold", textTransform: "capitalize", color: "#000000", bgcolor: riskAssessment.includes("individual") ? "#6ECA35" : "#ffffff", ":hover": { bgcolor: "#6ECA35", } }}>
                                                Individual
                                            </Button>
                                            <Button color={"primary"} variant="contained" onClick={() => setRiskAssessment("home")} sx={{ height: 30, boxShadow: "0px 1px 5px 1px lightgrey", fontSize: "1em", bgcolor: "#ffffff", fontFamily: "Roboto-Bold", textTransform: "capitalize", color: "#000000", bgcolor: riskAssessment.includes("home") ? "#6ECA35" : "#ffffff", ":hover": { bgcolor: "#6ECA35", } }}>
                                                Home
                                            </Button>
                                        </Stack>
                                    </Box>
                                    <Box sx={{ borderRadius: 4, bgcolor: "#F7F7F7", display: "flex", mx: 4, height: "92%", overflowY: "auto" }}>
                                        {riskAssessment.includes("individual") && (
                                            <Box sx={{ px: 4, mb: 2, width: "100%" }}>
                                                {individualRiskData?.map((v, i) => {
                                                    return (
                                                        <List key={v?.category} sx={{ bgcolor: "#F7F7F7", p: 0, my: 1 }}>
                                                            <ListItem>
                                                                <ListItemText primaryTypographyProps={{
                                                                    fontFamily: "Roboto-Bold",
                                                                    fontSize: "1.2em",
                                                                    color: "primary"
                                                                }} primary={v.category} />
                                                                <Stack direction={"row"} spacing={2}>
                                                                    {v?.collapse ?
                                                                        <Button sx={{ borderRadius: 3, width: 40, minWidth: 40, height: 35 }} onClick={() => handleCollapseRisk(i, individualRiskData, setIndividualRisk)} variant='contained' color='primary'>
                                                                            <ExpandLess sx={{ color: "#fff", fontSize: 25 }} />
                                                                        </Button>
                                                                        : <Button sx={{ borderRadius: 3, width: 40, minWidth: 40, height: 35 }} onClick={() => handleCollapseRisk(i, individualRiskData, setIndividualRisk)} variant='contained' color='primary'>
                                                                            <ExpandMore sx={{ color: "#fff", fontSize: 25 }} />
                                                                        </Button>
                                                                    }
                                                                </Stack>
                                                            </ListItem>
                                                            <Collapse in={v?.collapse} timeout="auto" unmountOnExit>
                                                                <Box sx={{ mx: 2, borderRadius: 4, bgcolor: "#fff", pb: 2 }}>
                                                                    {v?.questionList?.map((vN, iN) => {
                                                                        return (
                                                                            <Box key={vN.question_title + iN} sx={{ pt: 2, mx: 4 }}>
                                                                                <Typography color="secondary.text" sx={{ fontFamily: "Roboto-Medium", fontSize: "1em" }}>
                                                                                    {vN.question_title}
                                                                                </Typography>
                                                                                <Box sx={{ borderRadius: 4, bgcolor: "#F7F7F7", display: "flex", }}>
                                                                                    <Box sx={{ width: "20%", m: 2 }}>
                                                                                        <Typography color="secondary.text2" sx={{ fontFamily: "Roboto-Medium", fontSize: "1em" }}>
                                                                                            Risk To Indvidual:
                                                                                        </Typography>
                                                                                        <Typography color={createColor(vN?.answer_type.risk_to_individual)} sx={{ fontFamily: "Roboto-Medium", fontSize: "1em" }}>
                                                                                            {vN?.answer_type.risk_to_individual || "Nill"}
                                                                                        </Typography>
                                                                                    </Box>
                                                                                    <Box sx={{ width: "20%", m: 2 }}>
                                                                                        <Typography color="secondary.text2" sx={{ fontFamily: "Roboto-Medium", fontSize: "1em" }}>
                                                                                            Risk To Staff:
                                                                                        </Typography>
                                                                                        <Typography color={createColor(vN?.answer_type.risk_to_staff)} sx={{ fontFamily: "Roboto-Medium", fontSize: "1em" }}>
                                                                                            {vN?.answer_type.risk_to_staff || "Nill"}
                                                                                        </Typography>
                                                                                    </Box>
                                                                                    <Box sx={{ width: "20%", m: 2 }}>
                                                                                        <Typography color="secondary.text2" sx={{ fontFamily: "Roboto-Medium", fontSize: "1em" }}>
                                                                                            Risk To Others:
                                                                                        </Typography>
                                                                                        <Typography color={createColor(vN?.answer_type.risk_to_other)} sx={{ fontFamily: "Roboto-Medium", fontSize: "1em" }}>
                                                                                            {vN?.answer_type.risk_to_other || "Nill"}
                                                                                        </Typography>
                                                                                    </Box>
                                                                                    <Box sx={{ width: "20%", m: 2 }}>
                                                                                        <Typography color="secondary.text2" sx={{ fontFamily: "Roboto-Medium", fontSize: "1em" }}>
                                                                                            Source Of Information:
                                                                                        </Typography>
                                                                                        <Typography color="primary.text" sx={{ fontFamily: "Roboto-Medium", fontSize: "1em" }}>
                                                                                            {vN?.answer_type.source_of_information || "Nill"}
                                                                                        </Typography>
                                                                                    </Box>
                                                                                    <Box sx={{ width: "20%", m: 2 }}>
                                                                                        <Typography color="secondary.text2" sx={{ fontFamily: "Roboto-Medium", fontSize: "1em" }}>
                                                                                            Plan Guide:
                                                                                        </Typography>
                                                                                        <Typography color="primary.text" sx={{ fontFamily: "Roboto-Medium", fontSize: "1em" }}>
                                                                                            {vN?.answer_type.management_guides}
                                                                                        </Typography>
                                                                                    </Box>
                                                                                </Box>
                                                                            </Box>
                                                                        )
                                                                    })}
                                                                </Box>
                                                            </Collapse>
                                                        </List>
                                                    )
                                                })}
                                            </Box>
                                        )}
                                        {riskAssessment.includes("home") && (
                                            <Box sx={{ px: 4, mb: 2, width: "100%" }}>
                                                {homeRiskData?.map((v, i) => {
                                                    return (
                                                        <List key={v.category + i} sx={{ bgcolor: "#F7F7F7", p: 0, my: 1 }}>
                                                            <ListItem>
                                                                <ListItemText primaryTypographyProps={{
                                                                    fontFamily: "Roboto-Bold",
                                                                    fontSize: "1.2em",
                                                                    color: "primary"
                                                                }} primary={v.category} />
                                                                <Stack direction={"row"} spacing={2}>
                                                                    {v?.collapse ?
                                                                        <Button sx={{ borderRadius: 3, width: 40, minWidth: 40, height: 35 }} onClick={() => handleCollapseRisk(i, homeRiskData, setHomeRiskData)} variant='contained' color='primary'>
                                                                            <ExpandLess sx={{ color: "#fff", fontSize: 25 }} />
                                                                        </Button>
                                                                        : <Button sx={{ borderRadius: 3, width: 40, minWidth: 40, height: 35 }} onClick={() => handleCollapseRisk(i, homeRiskData, setHomeRiskData)} variant='contained' color='primary'>
                                                                            <ExpandMore sx={{ color: "#fff", fontSize: 25 }} />
                                                                        </Button>
                                                                    }
                                                                </Stack>
                                                            </ListItem>
                                                            <Collapse in={v.collapse} timeout="auto" unmountOnExit>
                                                                <Box sx={{ mx: 2, borderRadius: 4, bgcolor: "#fff", pb: 2 }}>
                                                                    {v.questionList.map((vN, iN) => {
                                                                        return (
                                                                            <Box key={vN.question_title + iN} sx={{ pt: 2, mx: 4 }}>
                                                                                <Typography color="secondary.text" sx={{ fontFamily: "Roboto-Medium", fontSize: "1em" }}>
                                                                                    {vN.question_title}
                                                                                </Typography>
                                                                                {/* {typeof vN.answers === "string" && (
                                                                                    <Box sx={{ borderRadius: 4, bgcolor: "#F7F7F7", display: "flex", }}>
                                                                                        <Box sx={{ width: "20%", m: 2 }}>
                                                                                            <Typography color="secondary.text2" sx={{ fontFamily: "Roboto-Medium", fontSize: "1em" }}>
                                                                                                Answer:
                                                                                            </Typography>
                                                                                            <Typography color={"primary.text"} sx={{ fontFamily: "Roboto-Medium", fontSize: "1em" }}>
                                                                                                {vN.answers}
                                                                                            </Typography>
                                                                                        </Box>
                                                                                    </Box>
                                                                                )}
                                                                                {vN?.answers?.__typename?.includes("Single") && (
                                                                                    <Box sx={{ borderRadius: 4, bgcolor: "#F7F7F7", display: "flex", }}>
                                                                                        <Box sx={{ width: "20%", m: 2 }}>
                                                                                            <Typography color="secondary.text2" sx={{ fontFamily: "Roboto-Medium", fontSize: "1em" }}>
                                                                                                Answer:
                                                                                            </Typography>
                                                                                            <Typography color={"primary.text"} sx={{ fontFamily: "Roboto-Medium", fontSize: "1em" }}>
                                                                                                {vN?.answers?.drop_down_option_lovs?.data[0]?.attributes?.title}
                                                                                            </Typography>
                                                                                        </Box>
                                                                                    </Box>
                                                                                    
                                                                                )} */}
                                                                                {/* {vN?.answers?.__typename?.includes("HomeRisk") && ( */}
                                                                                <Box sx={{ borderRadius: 4, bgcolor: "#F7F7F7", display: "flex", }}>
                                                                                    <Box sx={{ width: "20%", m: 2 }}>
                                                                                        <Typography color="secondary.text2" sx={{ fontFamily: "Roboto-Medium", fontSize: "1em" }}>
                                                                                            Selected Options:
                                                                                        </Typography>
                                                                                        <Typography color={"primary.text"} sx={{ fontFamily: "Roboto-Medium", fontSize: "1em" }}>
                                                                                            {vN?.answer_type?.selected_options || "Nill"}
                                                                                        </Typography>
                                                                                    </Box>
                                                                                    <Box sx={{ width: "20%", m: 2 }}>
                                                                                        <Typography color="secondary.text2" sx={{ fontFamily: "Roboto-Medium", fontSize: "1em" }}>
                                                                                            Details Action:
                                                                                        </Typography>
                                                                                        <Typography color={"primary.text"} sx={{ fontFamily: "Roboto-Medium", fontSize: "1em" }}>
                                                                                            {vN?.answer_type?.details_action || "Nill"}
                                                                                        </Typography>
                                                                                    </Box>
                                                                                    <Box sx={{ width: "20%", m: 2 }}>
                                                                                        <Typography color="secondary.text2" sx={{ fontFamily: "Roboto-Medium", fontSize: "1em" }}>
                                                                                            Rating:
                                                                                        </Typography>
                                                                                        <Typography color={"primary.text"} sx={{ fontFamily: "Roboto-Medium", fontSize: "1em" }}>
                                                                                            {vN?.answer_type?.rating || "Nill"}
                                                                                        </Typography>
                                                                                    </Box>
                                                                                </Box>
                                                                                {/* )} */}
                                                                            </Box>
                                                                        )
                                                                    })}
                                                                </Box>
                                                            </Collapse>
                                                        </List>
                                                    )
                                                })}
                                            </Box>
                                        )}
                                    </Box>
                                </Grid>
                            )}
                            {activeTab.includes("reports") && (
                                <Grid item={true} xs={12} sm={12} md={12} lg={12} sx={{ my: 2, py: 2, backgroundColor: "#fff", mr: 3, height: "calc(66vh)", border: "1px solid #6ECA35", borderRadius: 4, }}>
                                    <Box sx={{ display: "flex", mx: 4, justifyContent: "flex-end" }}>
                                        <Stack direction={"row"} spacing={2}>
                                            <Button color={"primary"} variant="contained" onClick={() => setIncidentsTab("incidents")} sx={{ height: 30, boxShadow: "0px 1px 5px 1px lightgrey", fontSize: "1em", bgcolor: "#ffffff", fontFamily: "Roboto-Bold", textTransform: "capitalize", color: "#000000", bgcolor: incidentsTab.includes("incidents") ? "#6ECA35" : "#ffffff", ":hover": { bgcolor: "#6ECA35", } }}>
                                                Incidents
                                            </Button>
                                            <Button color={"primary"} variant="contained" onClick={() => setIncidentsTab("complaints")} sx={{ height: 30, boxShadow: "0px 1px 5px 1px lightgrey", fontSize: "1em", bgcolor: "#ffffff", fontFamily: "Roboto-Bold", textTransform: "capitalize", color: "#000000", bgcolor: incidentsTab.includes("complaints") ? "#6ECA35" : "#ffffff", ":hover": { bgcolor: "#6ECA35", } }}>
                                                Complaints
                                            </Button>
                                            <Button color={"primary"} variant="contained" onClick={() => setIncidentsTab("feedbacks")} sx={{ height: 30, boxShadow: "0px 1px 5px 1px lightgrey", fontSize: "1em", bgcolor: "#ffffff", fontFamily: "Roboto-Bold", textTransform: "capitalize", color: "#000000", bgcolor: incidentsTab.includes("feedbacks") ? "#6ECA35" : "#ffffff", ":hover": { bgcolor: "#6ECA35", } }}>
                                                Feedbacks
                                            </Button>
                                        </Stack>
                                    </Box>
                                    {incidentsTab.includes("incidents") && (
                                        <Box sx={{ display: "flex", flexDirection: "row", my: 2, }}>
                                            <Box sx={{ mx: 4, overflowY: "auto", my: 1, width: "100%", borderRadius: 5, height: 250, bgcolor: "#F7F7F7" }}>
                                                <Box sx={{ p: 3 }}>
                                                    <Typography color="primary" sx={{ fontFamily: "Roboto-Medium", fontSize: "1.2em" }}>
                                                        Incident Details
                                                    </Typography>
                                                </Box>
                                                {/* list */}
                                                <Box sx={{ p: 3, }}>
                                                </Box>
                                            </Box>
                                        </Box>
                                    )}
                                    {incidentsTab.includes("complaints") && (
                                        <Box sx={{ display: "flex", flexDirection: "row", my: 2, }}>
                                            <Box sx={{ mx: 4, overflowY: "auto", my: 1, width: "100%", borderRadius: 5, height: 250, bgcolor: "#F7F7F7" }}>
                                                <Box sx={{ p: 3 }}>
                                                    <Typography color="primary" sx={{ fontFamily: "Roboto-Medium", fontSize: "1.2em" }}>
                                                        Complaints Details
                                                    </Typography>
                                                </Box>
                                                {/* list */}
                                                <Box sx={{ p: 3, }}>
                                                </Box>
                                            </Box>
                                        </Box>
                                    )}
                                    {incidentsTab.includes("feedbacks") && (
                                        <Box sx={{ display: "flex", flexDirection: "row", my: 2, }}>
                                            <Box sx={{ mx: 4, overflowY: "auto", my: 1, width: "100%", borderRadius: 5, height: 250, bgcolor: "#F7F7F7" }}>
                                                <Box sx={{ p: 3 }}>
                                                    <Typography color="primary" sx={{ fontFamily: "Roboto-Medium", fontSize: "1.2em" }}>
                                                        Feedbacks Details
                                                    </Typography>
                                                </Box>
                                                {/* list */}
                                                <Box sx={{ p: 3, }}>
                                                </Box>
                                            </Box>
                                        </Box>
                                    )}
                                </Grid>
                            )}
                            {activeTab.includes("safety") && (
                                <Grid item={true} xs={12} sm={12} md={12} lg={12} sx={{ my: 2, py: 2, backgroundColor: "#fff", mr: 3, height: "calc(66vh)", border: "1px solid #6ECA35", borderRadius: 4, }}>
                                    <Box sx={{ display: "flex", flexDirection: "column", mx: 4, height: "100%", overflow: "auto" }}>
                                        {safetyPlans?.map((value, index) => {
                                            return (
                                                <Box key={index} sx={{ display: "flex", width: "100%", my: 0.5 }}>
                                                    <Box sx={{ width: "8%", height: "100%", display: "flex", justifyContent: "center", alignItems: "center", }}>
                                                        <Box sx={{ height: "30%", display: "flex", alignItems: "center", transform: "rotate(-90deg)" }}>
                                                            <Typography color="secondary.text2" sx={{ color: value?.category?.toLowerCase() === "green" ? "#35BE00" : value?.category?.toLowerCase() === "amber" ? "#FFBF00" : value?.category, textTransform: "uppercase", fontFamily: "Roboto-Medium", fontSize: "2em" }}>
                                                                {value.category}
                                                            </Typography>
                                                        </Box>
                                                    </Box>
                                                    <Box sx={{ width: "92%", height: "100%", }}>
                                                        <Box sx={{ width: "100%", bgcolor: "#F7F7F7", height: "100%", borderRadius: 4, display: "flex", justifyContent: "space-evenly", }}>
                                                            {value?.questionAnswer?.map((vN, iN) => {
                                                                return (
                                                                    <Box key={iN} sx={{ width: "47%", height: "100%" }}>
                                                                        <Box sx={{ height: "10%", my: 1 }}>
                                                                            <Typography color="priamry" sx={{ color: "#35BE00", fontFamily: "Roboto-Medium", fontSize: "1em" }}>
                                                                                {vN?.question?.slice(0, vN?.question?.indexOf("("))}
                                                                            </Typography>
                                                                        </Box>
                                                                        <Box sx={{ borderRadius: 4, bgcolor: "#fff", my: 1, px: 2, px: 1 }}>
                                                                            {vN.answers.map((v, i) => {
                                                                                return (
                                                                                    <Typography key={i} color="secondary.text2" sx={{ fontFamily: "Roboto-Regular", fontSize: "1em", }}>
                                                                                        <FiberManualRecordIcon color='#000000' sx={{ fontSize: 10, }} />
                                                                                        <span style={{ paddingLeft: "1%", fontSize: "0.8em" }}>{v.text}</span>
                                                                                    </Typography>
                                                                                )
                                                                            })}
                                                                        </Box>
                                                                    </Box>
                                                                )
                                                            })}
                                                        </Box>
                                                    </Box>
                                                </Box>
                                            )
                                        })}
                                    </Box>
                                </Grid>
                            )}
                            {activeTab.includes("details") && (
                                <Grid item={true} xs={12} sm={12} md={12} lg={12} sx={{ my: 2, py: 2, backgroundColor: "#fff", mr: 3, height: "calc(66vh)", border: "1px solid #6ECA35", borderRadius: 4, }}>
                                    <Box sx={{ display: "flex", height: "100%", flexDirection: "row", justifyContent: "space-evenly", overflowY: "auto" }}>
                                        <Box sx={{ display: "flex", width: "48%", px: 2, flexDirection: "column" }}>
                                            {/* personal information */}
                                            <Box sx={{ borderRadius: 4, bgcolor: "#F7F7F7", width: "100%", my: 1, overflowY: "auto" }}>
                                                <Box sx={{ px: 4, pt: 2 }}>
                                                    <Typography color="primary" sx={{ fontFamily: "Roboto-Medium", fontSize: "1.2em" }}>
                                                        Personal Iinformation
                                                    </Typography>
                                                </Box>
                                                <Box sx={{ px: 4, mb: 2 }}>
                                                    {personal.map((v, i) => {
                                                        return (
                                                            <List key={v.title} component={Paper} sx={{ p: 0, my: 1 }}>
                                                                <ListItem>
                                                                    <ListItemText primaryTypographyProps={{
                                                                        fontFamily: "Roboto-Bold",
                                                                        fontSize: "1.2em"
                                                                    }} primary={v.title} />
                                                                    <Stack direction={"row"} spacing={2}>
                                                                        <IconButton>
                                                                            <BorderColorOutlinedIcon color={"primary"} />
                                                                        </IconButton>
                                                                        {v.collapse ?
                                                                            <Button sx={{ borderRadius: 3, width: 40, minWidth: 40, height: 35 }} onClick={() => handleCollapse(i, personal, setPersonal)} variant='contained' color='primary'>
                                                                                <ExpandLess sx={{ color: "#fff", fontSize: 25 }} />
                                                                            </Button>
                                                                            : <Button sx={{ borderRadius: 3, width: 40, minWidth: 40, height: 35 }} onClick={() => handleCollapse(i, personal, setPersonal)} variant='contained' color='primary'>
                                                                                <ExpandMore sx={{ color: "#fff", fontSize: 25 }} />
                                                                            </Button>
                                                                        }
                                                                    </Stack>
                                                                </ListItem>
                                                                <Collapse in={v.collapse} timeout="auto" unmountOnExit>
                                                                    <Box sx={{ display: "flex", mb: 1, justifyContent: "center", }}>
                                                                        <Box sx={{ display: "flex", flexWrap: "wrap", width: "90%", }}>
                                                                            {Object.keys(v).map((key) => {
                                                                                if (key !== "collapse" && key !== "title") {
                                                                                    return (
                                                                                        <Box key={key} sx={{ width: "50%", }}>
                                                                                            <Typography color="secondary.text3" sx={{ textTransform: "capitalize", fontFamily: "Roboto-Regular", fontSize: "1em" }}>
                                                                                                {key.replace(/_/g, " ")}: <span style={{ textTransform: "lowercase", color: "#3A89CC" }}>{v[key]}</span>
                                                                                            </Typography>
                                                                                        </Box>
                                                                                    )
                                                                                }
                                                                            })}
                                                                        </Box>
                                                                    </Box>
                                                                </Collapse>
                                                            </List>
                                                        )
                                                    })}
                                                </Box>
                                            </Box>
                                            <Box sx={{ borderRadius: 4, bgcolor: "#F7F7F7", width: "100%", overflowY: "auto" }}>
                                                <Box sx={{ px: 4, pt: 2 }}>
                                                    <Typography color="primary" sx={{ fontFamily: "Roboto-Medium", fontSize: "1.2em" }}>
                                                        Goals & Notes
                                                    </Typography>
                                                </Box>
                                                <Box sx={{ px: 4, mb: 2 }}>
                                                    {goalsAndNotes?.clientGoals?.map((v, i) => {
                                                        return (
                                                            <List key={v?.attributes?.goal} component={Paper} sx={{ p: 0, my: 1 }}>
                                                                <ListItem>
                                                                    <ListItemText primaryTypographyProps={{
                                                                        fontFamily: "Roboto-Bold",
                                                                        fontSize: "1.2em"
                                                                    }} primary={v?.attributes?.goal} secondary={v?.attributes?.client_answer_lovs_ref_id?.data[0]?.attributes?.drop_down_option_lov_id?.data?.attributes?.title} />
                                                                    <Stack direction={"row"} spacing={2}>
                                                                        <IconButton>
                                                                            <BorderColorOutlinedIcon color={"primary"} />
                                                                        </IconButton>
                                                                        {v.collapse ?
                                                                            <Button sx={{ borderRadius: 3, width: 40, minWidth: 40, height: 35 }} onClick={() => handleGoalsAndNotes(i, "clientGoals")} variant='contained' color='primary'>
                                                                                <ExpandLess sx={{ color: "#fff", fontSize: 25 }} />
                                                                            </Button>
                                                                            : <Button sx={{ borderRadius: 3, width: 40, minWidth: 40, height: 35 }} onClick={() => handleGoalsAndNotes(i, "clientGoals")} variant='contained' color='primary'>
                                                                                <ExpandMore sx={{ color: "#fff", fontSize: 25 }} />
                                                                            </Button>
                                                                        }
                                                                    </Stack>
                                                                </ListItem>
                                                                <Collapse in={v.collapse} timeout="auto" unmountOnExit>
                                                                    <Box sx={{ display: "flex", justifyContent: "center", alignItems: 'center', mb: 3 }}>
                                                                        <Box sx={{ width: "45%", }}>
                                                                            <Typography color="secondary.text3" sx={{ fontFamily: "Roboto-Regular", fontSize: "1em" }}>
                                                                                Start Date: <span style={{ color: "#3A89CC" }}>{v?.attributes?.start_date}</span>
                                                                            </Typography>
                                                                        </Box>
                                                                        <Box sx={{ width: "45%", }}>
                                                                            <Typography color="secondary.text3" sx={{ fontFamily: "Roboto-Regular", fontSize: "1em" }}>
                                                                                End Date: <span style={{ color: "#3A89CC" }}>{v?.attributes?.end_date}</span>
                                                                            </Typography>
                                                                        </Box>
                                                                    </Box>
                                                                </Collapse>
                                                            </List>
                                                        )
                                                    })}
                                                    {goalsAndNotes?.clientNotes?.map((v, i) => {
                                                        return (
                                                            <List key={v?.attributes?.title} component={Paper} sx={{ p: 0, my: 1 }}>
                                                                <ListItem>
                                                                    <ListItemText primaryTypographyProps={{
                                                                        fontFamily: "Roboto-Bold",
                                                                        fontSize: "1.2em"
                                                                    }} primary={v?.attributes?.title} />
                                                                    <Stack direction={"row"} spacing={2}>
                                                                        <IconButton>
                                                                            <BorderColorOutlinedIcon color={"primary"} />
                                                                        </IconButton>
                                                                        {v.collapse ?
                                                                            <Button sx={{ borderRadius: 3, width: 40, minWidth: 40, height: 35 }} onClick={() => handleGoalsAndNotes(i, "clientNotes")} variant='contained' color='primary'>
                                                                                <ExpandLess sx={{ color: "#fff", fontSize: 25 }} />
                                                                            </Button>
                                                                            : <Button sx={{ borderRadius: 3, width: 40, minWidth: 40, height: 35 }} onClick={() => handleGoalsAndNotes(i, "clientNotes")} variant='contained' color='primary'>
                                                                                <ExpandMore sx={{ color: "#fff", fontSize: 25 }} />
                                                                            </Button>
                                                                        }
                                                                    </Stack>
                                                                </ListItem>
                                                                <Collapse in={v.collapse} timeout="auto" unmountOnExit>
                                                                    <Box sx={{ display: "flex", justifyContent: "center", alignItems: 'center', mb: 3 }}>
                                                                        <Box sx={{ width: "90%", }}>
                                                                            <Typography color="secondary.text3" sx={{ fontFamily: "Roboto-Regular", fontSize: "1em" }}>
                                                                                Comments: <span style={{ color: "#3A89CC" }}>{v?.attributes?.comments}</span>
                                                                            </Typography>
                                                                        </Box>
                                                                    </Box>
                                                                </Collapse>
                                                            </List>
                                                        )
                                                    })}
                                                </Box>
                                            </Box>
                                        </Box>
                                        <Box sx={{ display: "flex", width: "48%", px: 2, flexDirection: "column" }}>
                                            {/* Core Details */}
                                            <Box sx={{ borderRadius: 4, bgcolor: "#F7F7F7", height: "100%", width: "100%", my: 1, overflowY: "auto" }}>
                                                <Box sx={{ px: 4, pt: 2 }}>
                                                    <Typography color="primary" sx={{ fontFamily: "Roboto-Medium", fontSize: "1.2em" }}>
                                                        Care Details
                                                    </Typography>
                                                </Box>
                                                <Box sx={{ px: 4, mb: 2 }}>
                                                    {careDetails?.serviceInfo?.map((v, i) => {
                                                        return (
                                                            <List key={v.___typename} component={Paper} sx={{ p: 0, my: 1 }}>
                                                                <ListItem>
                                                                    <ListItemText primaryTypographyProps={{
                                                                        fontFamily: "Roboto-Bold",
                                                                        fontSize: "1.2em"
                                                                    }} primary={"Service Details"} />
                                                                    <Stack direction={"row"} spacing={2}>
                                                                        <IconButton>
                                                                            <BorderColorOutlinedIcon color={"primary"} />
                                                                        </IconButton>
                                                                        {v.collapse ?
                                                                            <Button sx={{ borderRadius: 3, width: 40, minWidth: 40, height: 35 }} onClick={() => handleCareDetails(i, "serviceInfo")} variant='contained' color='primary'>
                                                                                <ExpandLess sx={{ color: "#fff", fontSize: 25 }} />
                                                                            </Button>
                                                                            : <Button sx={{ borderRadius: 3, width: 40, minWidth: 40, height: 35 }} onClick={() => handleCareDetails(i, "serviceInfo")} variant='contained' color='primary'>
                                                                                <ExpandMore sx={{ color: "#fff", fontSize: 25 }} />
                                                                            </Button>
                                                                        }
                                                                    </Stack>
                                                                </ListItem>
                                                                <Collapse in={v.collapse} timeout="auto" unmountOnExit>
                                                                    <Box sx={{ display: "flex", mb: 1, justifyContent: "center", }}>
                                                                        <Box sx={{ display: "flex", flexWrap: "wrap", width: "90%", }}>
                                                                            {v?.attributes?.client_answer_lovs_references?.data.map((vN, iN) => {
                                                                                return (
                                                                                    <Box key={vN?.__typename} sx={{ width: "50%", }}>
                                                                                        <Typography color="secondary.text3" sx={{ fontFamily: "Roboto-Regular", fontSize: "1em" }}>
                                                                                            {vN.attributes.design_frontend_input_id.data.attributes.input_name}: <span style={{ color: "#3A89CC" }}>{vN?.attributes?.drop_down_option_lov_id?.data?.attributes?.title}</span>
                                                                                        </Typography>
                                                                                    </Box>
                                                                                )
                                                                            })}
                                                                        </Box>
                                                                    </Box>
                                                                    <Box sx={{ display: "flex", mb: 1, justifyContent: "center", }}>
                                                                        <Box sx={{ display: "flex", flexWrap: "wrap", width: "90%", }}>
                                                                            <Box sx={{ width: "50%", }}>
                                                                                <Typography color="secondary.text3" sx={{ fontFamily: "Roboto-Regular", fontSize: "1em" }}>
                                                                                    Notes: <span style={{ color: "#3A89CC" }}>{v?.attributes?.Notes}</span>
                                                                                </Typography>
                                                                            </Box>
                                                                        </Box>
                                                                    </Box>
                                                                </Collapse>
                                                            </List>
                                                        )
                                                    })}
                                                    {careDetails?.preferenceWorker?.map((v, i) => {
                                                        return (
                                                            <List key={v.___typename} component={Paper} sx={{ p: 0, my: 1 }}>
                                                                <ListItem>
                                                                    <ListItemText primaryTypographyProps={{
                                                                        fontFamily: "Roboto-Bold",
                                                                        fontSize: "1.2em"
                                                                    }} primary={"Preference For Staff Worker"} />
                                                                    <Stack direction={"row"} spacing={2}>
                                                                        <IconButton>
                                                                            <BorderColorOutlinedIcon color={"primary"} />
                                                                        </IconButton>
                                                                        {v.collapse ?
                                                                            <Button sx={{ borderRadius: 3, width: 40, minWidth: 40, height: 35 }} onClick={() => handleCareDetails(i, "preferenceWorker")} variant='contained' color='primary'>
                                                                                <ExpandLess sx={{ color: "#fff", fontSize: 25 }} />
                                                                            </Button>
                                                                            : <Button sx={{ borderRadius: 3, width: 40, minWidth: 40, height: 35 }} onClick={() => handleCareDetails(i, "preferenceWorker")} variant='contained' color='primary'>
                                                                                <ExpandMore sx={{ color: "#fff", fontSize: 25 }} />
                                                                            </Button>
                                                                        }
                                                                    </Stack>
                                                                </ListItem>
                                                                <Collapse in={v.collapse} timeout="auto" unmountOnExit>
                                                                    <Box sx={{ display: "flex", mb: 1, justifyContent: "center", }}>
                                                                        <Box sx={{ display: "flex", flexWrap: "wrap", width: "90%", }}>
                                                                            {v?.attributes?.client_answer_lovs_ref?.data.map((vN, iN) => {
                                                                                return (
                                                                                    <Box key={vN?.__typename} sx={{ width: "50%", }}>
                                                                                        <Typography color="secondary.text3" sx={{ fontFamily: "Roboto-Regular", fontSize: "1em" }}>
                                                                                            {vN.attributes.design_frontend_input_id.data.attributes.input_name}: <span style={{ color: "#3A89CC" }}>{vN?.attributes?.drop_down_option_lov_id?.data?.attributes?.title}</span>
                                                                                        </Typography>
                                                                                    </Box>
                                                                                )
                                                                            })}
                                                                            <Box sx={{ width: "50%", }}>
                                                                                <Typography color="secondary.text3" sx={{ fontFamily: "Roboto-Regular", fontSize: "1em" }}>
                                                                                    Age: <span style={{ color: "#3A89CC" }}>{v?.attributes?.age}</span>
                                                                                </Typography>
                                                                            </Box>
                                                                        </Box>
                                                                    </Box>
                                                                </Collapse>
                                                            </List>
                                                        )
                                                    })}
                                                </Box>
                                            </Box>
                                            <Box sx={{ borderRadius: 4, bgcolor: "#F7F7F7", height: "100%", width: "100%", my: 1, overflowY: "auto" }}>
                                                <Box sx={{ px: 4, pt: 2 }}>
                                                    <Typography color="primary" sx={{ fontFamily: "Roboto-Medium", fontSize: "1.2em" }}>
                                                        Monitoring & Review
                                                    </Typography>
                                                </Box>
                                                <Box sx={{ px: 4, mb: 2 }}>
                                                    {review?.checkList?.map((v, i) => {
                                                        return (
                                                            <List key={v?.__typename} component={Paper} sx={{ p: 0, my: 1 }}>
                                                                <ListItem>
                                                                    <ListItemText primaryTypographyProps={{
                                                                        fontFamily: "Roboto-Bold",
                                                                        fontSize: "1.2em"
                                                                    }} primary={v?.attributes?.check_list_title} />
                                                                    <Stack direction={"row"} spacing={2}>
                                                                        <IconButton>
                                                                            <BorderColorOutlinedIcon color={"primary"} />
                                                                        </IconButton>
                                                                        {v.collapse ?
                                                                            <Button sx={{ borderRadius: 3, width: 40, minWidth: 40, height: 35 }} onClick={() => handleReview(i, "checkList")} variant='contained' color='primary'>
                                                                                <ExpandLess sx={{ color: "#fff", fontSize: 25 }} />
                                                                            </Button>
                                                                            : <Button sx={{ borderRadius: 3, width: 40, minWidth: 40, height: 35 }} onClick={() => handleReview(i, "checkList")} variant='contained' color='primary'>
                                                                                <ExpandMore sx={{ color: "#fff", fontSize: 25 }} />
                                                                            </Button>
                                                                        }
                                                                    </Stack>
                                                                </ListItem>
                                                                <Collapse in={v.collapse} timeout="auto" unmountOnExit>
                                                                    <Box sx={{ display: "flex", mb: 1, justifyContent: "center", }}>
                                                                        <Box sx={{ display: "flex", flexWrap: "wrap", width: "90%", }}>
                                                                            {v?.attributes?.client_answer_lovs_refs?.data.map((vN, iN) => {
                                                                                return (
                                                                                    <Box key={vN?.__typename} sx={{ width: "50%", }}>
                                                                                        <Typography color="secondary.text3" sx={{ fontFamily: "Roboto-Regular", fontSize: "1em" }}>
                                                                                            {vN.attributes.design_frontend_input_id.data.attributes.input_name}: <span style={{ color: "#3A89CC" }}>{vN?.attributes?.drop_down_option_lov_id?.data?.attributes?.title}</span>
                                                                                        </Typography>
                                                                                    </Box>
                                                                                )
                                                                            })}
                                                                            <Box sx={{ width: "50%", }}>
                                                                                <Typography color="secondary.text3" sx={{ fontFamily: "Roboto-Regular", fontSize: "1em" }}>
                                                                                    Notification Time: <span style={{ color: "#3A89CC" }}>{v?.attributes?.notification_time.slice(0, 5)}</span>
                                                                                </Typography>
                                                                            </Box>
                                                                            <Box sx={{ width: "50%", }}>
                                                                                <Typography color="secondary.text3" sx={{ fontFamily: "Roboto-Regular", fontSize: "1em" }}>
                                                                                    Comments: <span style={{ color: "#3A89CC" }}>{v?.attributes?.check_list_comments}</span>
                                                                                </Typography>
                                                                            </Box>
                                                                        </Box>
                                                                    </Box>
                                                                </Collapse>
                                                            </List>
                                                        )
                                                    })}
                                                    {review?.planReview?.map((v, i) => {
                                                        return (
                                                            <List key={v?.__typename} component={Paper} sx={{ p: 0, my: 1 }}>
                                                                <ListItem>
                                                                    <ListItemText primaryTypographyProps={{
                                                                        fontFamily: "Roboto-Bold",
                                                                        fontSize: "1.2em"
                                                                    }} primary={"Plan Review"} />
                                                                    <Stack direction={"row"} spacing={2}>
                                                                        <IconButton>
                                                                            <BorderColorOutlinedIcon color={"primary"} />
                                                                        </IconButton>
                                                                        {v.collapse ?
                                                                            <Button sx={{ borderRadius: 3, width: 40, minWidth: 40, height: 35 }} onClick={() => handleReview(i, "planReview")} variant='contained' color='primary'>
                                                                                <ExpandLess sx={{ color: "#fff", fontSize: 25 }} />
                                                                            </Button>
                                                                            : <Button sx={{ borderRadius: 3, width: 40, minWidth: 40, height: 35 }} onClick={() => handleReview(i, "planReview")} variant='contained' color='primary'>
                                                                                <ExpandMore sx={{ color: "#fff", fontSize: 25 }} />
                                                                            </Button>
                                                                        }
                                                                    </Stack>
                                                                </ListItem>
                                                                <Collapse in={v.collapse} timeout="auto" unmountOnExit>
                                                                    <Box sx={{ display: "flex", mb: 1, justifyContent: "center", }}>
                                                                        <Box sx={{ display: "flex", flexWrap: "wrap", width: "90%", }}>
                                                                            <Box sx={{ width: "50%", }}>
                                                                                <Typography color="secondary.text3" sx={{ fontFamily: "Roboto-Regular", fontSize: "1em" }}>
                                                                                    Created By Plan: <span style={{ color: "#3A89CC" }}>{v?.attributes?.created_by_plan}</span>
                                                                                </Typography>
                                                                            </Box>
                                                                            <Box sx={{ width: "50%", }}>
                                                                                <Typography color="secondary.text3" sx={{ fontFamily: "Roboto-Regular", fontSize: "1em" }}>
                                                                                    Plan Created On: <span style={{ color: "#3A89CC" }}>{v?.attributes?.plan_created_on}</span>
                                                                                </Typography>
                                                                            </Box>
                                                                        </Box>
                                                                    </Box>
                                                                </Collapse>
                                                            </List>
                                                        )
                                                    })}
                                                </Box>
                                            </Box>
                                        </Box>
                                    </Box>
                                </Grid>
                            )}
                        </Grid>
                    </Grid>
                </Grid>
            )}
        </Grid>
    )
}

export default CleintDetailsViewV2;