import { ApolloClient, ApolloLink, createHttpLink, InMemoryCache } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';

export const API = "https://d-cennasupports-b80c24a4e4b6.herokuapp.com/api";
export const IMAGE = "https://d-cennasupports-b80c24a4e4b6.herokuapp.com";
export const AUTH_TOKEN = "738f692dd69e2294bb7bf4345bcd58023b1483cce99154760d5a18562671b6a17141c4c2bafec7d95983c26a9d4efac979a1b6906322cbdf9f3a2ea7125bb95f6de29af25c339b10ff319be0edbe4e8a56d18093192e663ec83d75d851c83d41238b575ffe52b71ce5788eb06b1f47fccecafc08435fccfdc68ec9b5ed3f32b6";
export const BEARER = "Bearer";

const httpLink = createHttpLink({
    // uri: "http://localhost:1337/api/graphql",
    uri: "https://d-cennasupports-b80c24a4e4b6.herokuapp.com/api/graphql"
});

// const upload = createHttpLink({
//     uri: "https://d-cennasupports-b80c24a4e4b6.herokuapp.com/graphql"
// });

const authLink = setContext((_, { headers }) => {
    // get the authentication token from local storage if it exists
    const token = localStorage.getItem('jwt');
    // return the headers to the context so httpLink can read them
    return {
        headers: {
            ...headers,
            authorization: token ? `Bearer ${AUTH_TOKEN}` : "",
        }
    }
});

export const client = new ApolloClient({
    // link: ApolloLink.split(
    //     operation => operation.getContext().clientName === 'upload',
    //     authLink.concat(upload), //if above 
    //     authLink.concat(httpLink)
    // ),
    link: authLink.concat(httpLink),
    cache: new InMemoryCache()
});

